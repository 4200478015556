import { extendTheme } from '@chakra-ui/react';
import EmojiTheme from '@components/Emoji/EmojiTheme';
import McCircleTheme from '@components/McCircle/McCircleTheme';
import colors from './colors';
import ButtonTheme from './components/ButtonTheme';
import HeadingTheme from './components/HeadingTheme';
import InputTheme from './components/InputTheme';
import ModalTheme from './components/ModalTheme';
import TabsTheme from './components/TabsTheme';
import TextTheme from './components/TextTheme';

// This is the default theme for the website.
// Room is the top scope.
// NOTE: if you modify this, you should run `npm run gen-theme-types` to update
// the types, which gives us stuff like autocomplete for theme colors (e.g. "MagicWhite")
const RoomTheme = extendTheme({
  colors,
  styles: {
    global: {
      body: {
        bg: 'MagicBlack',
        // white, not blue highlight
        WebkitTapHighlightColor: 'rgba(255, 255, 255, .4)',
        userSelect: 'none',
      },
    },
  },
  components: {
    Button: ButtonTheme,
    Tabs: TabsTheme,
    Emoji: EmojiTheme,
    McCircle: McCircleTheme,
    Text: TextTheme,
    Input: InputTheme,
    Heading: HeadingTheme,
    Modal: ModalTheme,
  },
});

export default RoomTheme;
