import React, { useEffect, useState } from 'react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import WebSocketCloseCode from '@common/WebSocketCloseCode';
import { PermanentlyDisconnectedCloseCode } from './RoomConnection';
import { useRoomConnection } from './hooks';

export function PermanentlyDisconnectedDialog() {
  const roomConnection = useRoomConnection();
  const cancelRef = React.useRef(null);
  const [kickedReason, setKickedReason] =
    useState<null | PermanentlyDisconnectedCloseCode>(null);

  useEffect(() => {
    roomConnection.onPermanentlyDisconnected = setKickedReason;
  }, [roomConnection]);

  let alertMessage;
  switch (kickedReason) {
    case WebSocketCloseCode.PlayerKicked: {
      alertMessage = 'You were kicked from the room by another player.';
      break;
    }
    case WebSocketCloseCode.ConnectionSuperseded: {
      alertMessage =
        'You were disconnected because you started playing in a new tab or window in this browser.';
      break;
    }
    case WebSocketCloseCode.VersionMismatch: {
      alertMessage =
        'A new version of the website is available. Please refresh the page to keep playing. If you were in the middle of a game, you might have to start over.';
      break;
    }
    default:
  }

  return (
    <AlertDialog
      isOpen={kickedReason !== null}
      onClose={() => void 0}
      leastDestructiveRef={cancelRef}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="red.800" textColor="MagicWhite">
          <AlertDialogHeader
            fontSize="xl"
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            <WarningTwoIcon mr="2" />
            Refresh to play
          </AlertDialogHeader>

          <AlertDialogBody>{alertMessage}</AlertDialogBody>

          <AlertDialogFooter>
            {/* <Button colorScheme="red" onClick={onClose} ml={3}>
              Kick the other instance
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
