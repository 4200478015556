import { defineStyleConfig } from '@chakra-ui/react';

const EmojiTheme = defineStyleConfig({
  baseStyle: {
    textShadow: '0px 2px 3px black',
  },
  sizes: {
    xs: {
      fontSize: '22px',
    },
    sm: {
      fontSize: '32px',
    },
    md: {
      fontSize: '65px',
    },
    lg: {
      fontSize: '88px',
    },
  },
  defaultProps: {
    size: 'sm',
  },
});

export default EmojiTheme;
