import PlayersDrawer from './PlayersDrawer';
import ProfileDrawer from './ProfileDrawer/ProfileDrawer';
import QrDrawer from './QrDrawer';

export type drawerType = keyof typeof drawerContent;

const drawerContent = {
  profile: ProfileDrawer,
  'profile-beforeInviting': ProfileDrawer,
  'profile-afterAcceptingInvite': ProfileDrawer,
  qr: QrDrawer,
  players: PlayersDrawer,
};

export default drawerContent;
