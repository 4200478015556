import { Circle, Grid } from '@chakra-ui/react';
import colors from './colors';

interface ColorPickerProps {
  onClick: (color: string) => void;
  selectedColor?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  onClick,
  selectedColor,
}) => {
  return (
    <Grid
      templateColumns="repeat(6, 50px)"
      gridAutoRows="50px"
      justifyItems="center"
      alignItems="center"
      pos="relative"
    >
      {Object.values(colors).map((color) => (
        <Circle
          key={color}
          bg={color}
          size={10}
          onClick={() => onClick(color)}
          borderColor="white"
          borderWidth={selectedColor === color ? '4px' : '0px'}
        />
      ))}
    </Grid>
  );
};

export default ColorPicker;
