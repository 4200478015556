import colors from 'src/theme/colors';
import { GameMetaData } from '../types';
import SolidStareCoverArt from './assets/blueberry.png';

const SolidStareMetaData: GameMetaData = {
  name: 'Solid Stare',
  cardColor: colors.MagicBlue,
  cardImage: SolidStareCoverArt,
  coverArt: SolidStareCoverArt,
  tagline: "Stare into each other's souls",
  howToPlay: 'Coming soon...',
  youWillNeed: [
    {
      emoji: '🧠',
      text: 'A heart',
    },
  ],
  minPlayers: 2,
};

export default SolidStareMetaData;
