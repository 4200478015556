import { defineStyleConfig } from '@chakra-ui/react';

const TextTheme = defineStyleConfig({
  baseStyle: {},
  sizes: {
    xs: {
      fontSize: 16,
    },
    sm: {
      fontSize: 18,
    },
    md: {
      fontSize: 24,
    },
    lg: {
      fontSize: 32,
    },
    xl: {
      fontSize: 36,
    },
    '2xl': {
      fontSize: 42,
    },
  },
  variants: {
    Bogart: {
      color: 'MagicBlack',
      fontFamily: 'Bogart',
    },
    Baloo: {
      color: 'MagicBlack',
      fontFamily: 'Baloo',
    },
    GreyCliff: {
      color: 'MagicBlack',
      fontFamily: 'GreyCliff CF',
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'GreyCliff',
  },
});

export default TextTheme;
