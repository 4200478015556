import { useMemo } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export interface McFlexProps extends FlexProps {
  col?: boolean;
  orient?: string;
}

const McFlex: React.FC<McFlexProps> = ({ children, col, orient, ...props }) => {
  const direction =
    props.flexDirection || props.flexDir || props.direction || col
      ? 'column'
      : 'row';

  const { justify, align } = useMemo(
    () => getAlignment(orient, direction as string), // TODO: fix typescript assertion
    [orient, direction]
  );

  // When a flex container is set to wrap, we need to set alignContent instead of alignItems
  // or there will be unexpected space between the items on the wrapped lines.
  const alignContent = props.wrap === 'nowrap' ? undefined : align;

  return (
    <Flex
      className="McFlex" /* add className for easier debugging in DOM tree */
      flexDir={direction}
      justify={justify}
      align={align}
      alignContent={alignContent}
      w="100%"
      h="100%"
      {...props}
    >
      {children}
    </Flex>
  );
};
McFlex.displayName = 'McFlex';

function getAlignment(orient = '', direction = 'row') {
  let justify = 'center';
  let align = 'center';

  const parsedAlignments = orient.split(' ');

  parsedAlignments.forEach((alignment) => {
    switch (alignment) {
      case 'top':
        if (direction === 'row') {
          align = 'flex-start';
        } else {
          justify = 'flex-start';
        }
        break;
      case 'bottom':
        if (direction === 'row') {
          align = 'flex-end';
        } else {
          justify = 'flex-end';
        }
        break;
      case 'left':
        if (direction === 'row') {
          justify = 'flex-start';
        } else {
          align = 'flex-start';
        }
        break;
      case 'right':
        if (direction === 'row') {
          justify = 'flex-end';
        } else {
          align = 'flex-end';
        }
        break;
      case 'space-between':
      case 'space-around':
      case 'space-evenly':
        justify = alignment;
        break;
      case 'stretch':
        align = alignment;
        break;
    }
  });

  return { justify, align };
}

export default McFlex;
