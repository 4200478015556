import { useCallback } from 'react';
import emojiNames from '@common/emojiNames';
import { RoomData } from '@common/games/Room/types';
import Player, { Cosmetic, PlayerId } from '@common/types/player';
import { IState } from '@common/types/state';
import { Immutable } from '@common/utils';
import { drawerType } from '@components/SystemDrawer/Content';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import defaultPlayer from 'src/constants/default-player';
import { atomWithSyncStorage, selectAtomDeepEquals } from './utils';
import { GameName, GameNames } from '@/games';

/*
 * gameState
 */
export const stateAtom = atom<Immutable<IState<RoomData>>>({
  scope: 'Room',
  data: {
    players: [],
    gameStartTimer: {
      gameName: GameNames.Lobby,
      secondsUntilGameStarts: 0,
    },
    playerIdWhoRequestedGame: null,
  },
  child: null,
} satisfies IState<RoomData>);
export const useGameState = () => useAtomValue(stateAtom);

/*
 * hasAcknowledgedAvatar
 * true if we've determined it's likely the user has mentally acknowledged their
 * player name and/or cosmetic. By default, this is false.
 * see: https://github.com/magic-circle-studio/magiccircle.gg/issues/323
 */
const hasAcknowledgedAvatarAtom = atomWithSyncStorage<boolean>(
  'hasAcknowledgedAvatar',
  localStorage,
  false
);

/**
 * hook that retrieves the value of hasAcknowledgedAvatarAtom.
 * This value indicates whether the user has acknowledged their avatar.
 * @returns The value of hasAcknowledgedAvatarAtom.
 */
export const useHasAcknowledgedAvatar = () =>
  useAtomValue(hasAcknowledgedAvatarAtom);

/**
 * useAcknowledgeAvatar is a hook that sets the hasAcknowledgedAvatarAtom to true.
 * This is used to indicate that the user has acknowledged their avatar.
 * @returns A function that when called, sets hasAcknowledgedAvatarAtom to true.
 */
export const useAcknowledgeAvatar = () => {
  const setHasAcknowledgedAvatar = useSetAtom(hasAcknowledgedAvatarAtom);
  const acknowledgeAvatar = () => setHasAcknowledgedAvatar(true);
  return acknowledgeAvatar;
};

/*
 * playerId
 */
export const playerIdAtom = atomWithSyncStorage<PlayerId>(
  'playerId',
  localStorage,
  ''
);
export const usePlayerId = () => useAtomValue(playerIdAtom);

/*
 * cosmetic
 */
export const cosmeticAtom = atomWithSyncStorage<Cosmetic>(
  'cosmeticAtom',
  localStorage,
  defaultPlayer.cosmetic
);
export const useCosmetic = () => useAtomValue(cosmeticAtom);
export const useSetCosmetic = () => useSetAtom(cosmeticAtom);
export const useEmoji = () => useAtomValue(cosmeticAtom).emoji;
export const useColor = () => useAtomValue(cosmeticAtom).color;

/*
 * player name
 */
export const playerNameAtom = atomWithSyncStorage<string>(
  'playerNameAtom',
  localStorage,
  defaultPlayer.name
);
export const usePlayerName = () => useAtomValue(playerNameAtom);
export const useSetPlayerName = () => useSetAtom(playerNameAtom);

const doesPlayerNameMatchTheirEmojiAtom = atom((get) => {
  const playerName = get(playerNameAtom);
  const playerCosmetic = get(cosmeticAtom);
  const emojiName = emojiNames[playerCosmetic.emoji];
  return playerName === emojiName;
});

export const usePlayerNameMatchesTheirEmoji = () =>
  useAtomValue(doesPlayerNameMatchTheirEmojiAtom);

/*
 * player
 */
export const playerAtom = atom<Player>((get) => {
  const playerId = get(playerIdAtom);
  const allPlayers = get(playersIncludingLeaversAtom);
  const player = allPlayers.find((p) => p.id === playerId);
  const cosmetic = get(cosmeticAtom);
  const name = get(playerNameAtom);
  return { ...(player ?? defaultPlayer), cosmetic, name };
});
export const usePlayer = () => useAtomValue(playerAtom);

/*
 * players
 */
const playersIncludingLeaversAtom = selectAtomDeepEquals(
  stateAtom,
  ({ data }) => data.players
);

const playersWithoutLeaversAtom = atom((get) =>
  get(playersIncludingLeaversAtom).filter((p) => !p.hasLeft)
);

const numPlayersWithoutLeavers = atom(
  (get) => get(playersWithoutLeaversAtom).length
);

export const useNumPlayersWithoutLeavers = () =>
  useAtomValue(numPlayersWithoutLeavers);

// export const usePlayers = () => useAtomValue(playersAtom);
export const usePlayersWithoutLeavers = () =>
  useAtomValue(playersWithoutLeaversAtom);
export const usePlayersIncludingLeavers = () =>
  useAtomValue(playersIncludingLeaversAtom);

export const usePlayerById = (id: PlayerId) => {
  const players = usePlayersIncludingLeavers();
  return players.find((p) => p.id === id);
};

export const usePlayersMeFirst = () => {
  const me = usePlayer();
  const others = usePlayersWithoutLeavers().filter((p) => p.id !== me.id);
  return [me, ...others].filter((p) => !!p);
};

export const usePlayersWithSecondsSinceLastSeen = () => {
  const playersMeFirst = usePlayersMeFirst();
  return playersMeFirst.map((player) => ({
    ...player,
    secondsSinceLastSeen: Math.floor((Date.now() - player.lastSeen) / 1000),
  }));
};

/*
 * gameDetails
 */
export const gameDetailsAtom = atom<GameName | null>(null);
export const useGameDetails = () => useAtomValue(gameDetailsAtom);
export const useSetGameDetails = () => useSetAtom(gameDetailsAtom);

/*
 * drawerType
 */
const drawerTypeAtom = atom<drawerType | null>(null);
export const useDrawerType = () => useAtomValue(drawerTypeAtom);

export function useOpenDrawer() {
  const setDrawerType = useSetAtom(drawerTypeAtom);
  return useCallback(
    (drawerType: drawerType) => {
      setDrawerType(drawerType);
    },
    [setDrawerType]
  );
}

export function useCloseDrawer() {
  const setDrawerType = useSetAtom(drawerTypeAtom);
  return () => {
    setDrawerType(null);
  };
}

/*
 * currentGameName
 */
export const currentGameNameAtom = atom<GameName | null>((get) => {
  const state = get(stateAtom);
  return (state.child?.scope as GameName) ?? null;
});
export const useCurrentGameName = () => useAtomValue(currentGameNameAtom);

const isMotionDeniedAtom = atomWithSyncStorage(
  'isMotionDenied',
  sessionStorage,
  false
);

export const useIsMotionDenied = () => useAtom(isMotionDeniedAtom);
