import React, { useEffect, useState } from 'react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { useRoomConnection } from './hooks';

const NumConsecutiveFailedAttemptsBeforeShowingDialog = 5;

export function ConnectionInterruptedDialog() {
  const roomConnection = useRoomConnection();
  const cancelRef = React.useRef(null);
  const [isConnectionInterrupted, setIsConnectionInterrupted] = useState(false);

  useEffect(() => {
    roomConnection.onReconnectionAttempt = (state) => {
      setIsConnectionInterrupted(
        !state.isConnected &&
          state.numConsecutiveAttempts >=
            NumConsecutiveFailedAttemptsBeforeShowingDialog
      );
    };
  }, [roomConnection]);

  return (
    <AlertDialog
      isOpen={isConnectionInterrupted}
      onClose={() => void 0}
      leastDestructiveRef={cancelRef}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="red.800" textColor="MagicWhite">
          <AlertDialogHeader
            fontSize="md"
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            <WarningTwoIcon mr="2" boxSize="24px" />
            You're not connected to the Internet
          </AlertDialogHeader>

          <AlertDialogBody>
            Please check your connection.
            <br />
            <br />
            Go ahead, we'll wait.
          </AlertDialogBody>

          <AlertDialogFooter>
            {/* <Button colorScheme="red" onClick={onClose} ml={3}>
              Kick the other instance
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
