import React from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import McFlex from '@components/McFlex/McFlex';
import PlayersDrawer from '@components/SystemDrawer/Content/PlayersDrawer';
import McGrid from '@components/ui/McGrid';
import ShareWidget from './ShareWidget';
import backArrow from '@/assets/svgs/back-arrow.svg';
import { useSendRoomMessage } from '@/hooks';

const InGameMenu: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sendRoomMessage = useSendRoomMessage();

  const onConfirmEndGame = () => {
    sendRoomMessage({ type: 'RequestGame', name: 'Lobby' });
  };

  return (
    <>
      <IconButton
        icon={<HamburgerIcon />}
        onClick={onOpen}
        size="lg"
        aria-label="In-Game Menu"
      />
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent bg="MagicBlack">
            <DrawerHeader>
              <McGrid gridTemplateColumns="auto 1fr" gap="2">
                <IconButton
                  icon={<Image src={backArrow} alt="Back Arrow" w={5} mr={2} />}
                  onClick={onClose}
                  size="lg"
                  aria-label="In-Game Menu"
                />
                {/* <McFlex justify="right">
                  <Heading size="lg">
                    {currentGameMetadata?.name || 'Unknown'}
                  </Heading>
                </McFlex> */}
              </McGrid>
            </DrawerHeader>
            <DrawerBody>
              <McFlex col gap="5" justify="top" mb="7">
                <Button
                  variant="red"
                  size="sm"
                  fontWeight="bold"
                  fontFamily="Greycliff CF"
                  onClick={onConfirmEndGame}
                >
                  End Game for Everyone
                </Button>
                <ShareWidget
                  height="350px"
                  width="100%"
                  py="1"
                  compact={true}
                />
                <PlayersDrawer pt="0" internalScrollbar={false} />
              </McFlex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default InGameMenu;
