import colors from 'src/theme/colors';
import { GameMetaData } from '../types';

const TellTaleHeartMetaData: GameMetaData = {
  name: 'Tell Tale Heart',
  cardColor: colors.MagicPurple,
  cardImage: '',
  coverArt: '',
  tagline: 'Hide and go seek your phone!',
  howToPlay: 'Coming soon...',
  youWillNeed: [
    {
      emoji: '🌽',
      text: 'Ears',
    },
  ],
  minPlayers: 2,
};

export default TellTaleHeartMetaData;
