import AvocadoMetaData from './Avocado/AvocadoMetaData';
import McMindMetaData from './McMind/McMindMetaData';
import NoseOlympicsMetaData from './NoseOlympics/NoseOlympicsMetaData';
import SolidStareMetaData from './SolidStare/SolidStareMetaData';
import TellTaleHeartMetaData from './TellTaleHeart/TellTaleHeartMetaData';
import TrebekMetaData from './Trebek/TrebekMetaData';
import YunomiMetaData from './Yunomi/YunomiMetaData';
import { GameMetaData } from './types';

const gameMetaDatas: Record<string, GameMetaData> = {
  Avocado: AvocadoMetaData,
  Trebek: TrebekMetaData,
  McMind: McMindMetaData,
  SolidStare: SolidStareMetaData,
  NoseOlympics: NoseOlympicsMetaData,
  TellTaleHeart: TellTaleHeartMetaData,
  Yunomi: YunomiMetaData,
};

export default gameMetaDatas;
