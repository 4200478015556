import { Atom } from 'jotai';
import { atomWithStorage, createJSONStorage, selectAtom } from 'jotai/utils';
import { isEqual } from 'lodash';

/**
 * Creates a `WritableAtom` that is immediately initialized with a value from the provided storage.
 * Unlike Jotai's default `atomWithStorage`, this function allows immediate access to the stored value upon page load,
 * not just after the first render.
 *
 * @param key The key used to reference the value in the storage.
 * @param storage The storage system to use, such as `localStorage` or `sessionStorage`.
 * @param defaultValue The default value to be used if no value is currently stored under the provided key.
 *                     If not provided, defaults to `null`.
 * @returns `WritableAtom` with the initial value loaded from storage, or the `defaultValue` if no stored value is found.
 * @template TValue The type of value the atom will hold.
 *
 * @example
 * const atom = atomWithSyncStorage<string>('myKey', sessionStorage, 'defaultString');
 */
export function atomWithSyncStorage<TValue>(
  key: string,
  storage: Storage,
  defaultValue: TValue
) {
  const valueFromStorage = storage.getItem(key);
  // Get the initial value from the storage, or use the defaultValue
  const initialValue: TValue = valueFromStorage
    ? JSON.parse(valueFromStorage)
    : defaultValue;
  // Now we can just return a regular `atomWithStorage` with the initial value from the storage
  const atom = atomWithStorage<TValue>(
    key,
    initialValue,
    createJSONStorage(() => storage)
  );
  atom.debugLabel = key;
  return atom;
}

/**
 *  Like selectAtom, but uses a deep equals function to determine if the value has changed
 * @param atom Atom to select from
 * @param selector A function that takes the atom's value and 'focuses' on a sub-value
 * @returns A new atom that only updates when the selector returns a new value
 */
export function selectAtomDeepEquals<Value, Result>(
  atom: Atom<Value>,
  selector: (value: Value) => Result
) {
  return selectAtom(atom, selector, isEqual);
}
