import { Flex, Text } from '@chakra-ui/layout';
import McFlex from '@components/McFlex/McFlex';
import QRCode from '@components/QRCode';
import { getUrl } from './utils';

const NoDesktopSupport: React.FC = () => {
  const url = getUrl();

  return (
    <McFlex col>
      <Text fontSize="100px">🥸🤳</Text>
      <Text variant="Bogart" size="lg" color="MagicWhite">
        Magic Circle is not supported on desktop yet.
      </Text>
      <Text variant="Bogart" size="lg" color="MagicWhite">
        Please use your mobile device.
      </Text>
      <Flex align="center" bg="white" mt={8} p={8} gap={4} borderRadius={12}>
        <QRCode value={url} size={300} />
      </Flex>
    </McFlex>
  );
};

export default NoDesktopSupport;
