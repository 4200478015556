import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { useDynamicScopes } from 'src/hooks';
import scopeThemes from 'src/theme/themes';

type Props = {
  children: React.ReactNode;
};

const McChakraProvider: React.FC<Props> = ({ children }) => {
  const scopes = useDynamicScopes();

  let scopePath = '';
  const themes = scopes.map((scope, idx) => {
    scopePath = idx === 0 ? scope : `${scopePath}.${scope}`;
    return scopeThemes[scopePath] || {};
  });

  return (
    <ChakraProvider
      theme={extendTheme(...themes, {
        config: { initialColorMode: 'dark' },
      })}
    >
      {children}
    </ChakraProvider>
  );
};

export default McChakraProvider;
