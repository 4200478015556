const emojiNames: Record<string, string> = {
  '😈': 'Devil',
  '👻': 'Ghost',
  '🐺': 'Wolf',
  '🐥': 'Chick',
  '🐬': 'Dolphin',
  '👽': 'Alien',
  '🤖': 'Robot',
  '🎃': 'Pumpkin',
  '🦄': 'Unicorn',
  '👹': 'Ogre',
  '🐶': 'Dog',
  '🐱': 'Cat',
  '🐭': 'Mouse',
  '🐰': 'Rabbit',
  '🐻': 'Bear',
  '🐯': 'Tiger',
  '🦁': 'Lion',
  '🐮': 'Cow',
  '🐷': 'Pig',
  '🐸': 'Frog',
  '🐵': 'Monkey',
  '🦝': 'Raccoon',
  '🐗': 'Boar',
  '🐴': 'Horse',
  '🦓': 'Zebra',
  '🦍': 'Gorilla',
  '🐼': 'Panda',
  '🐻‍❄️': 'Polar bear',
  '🐨': 'Koala',
  '🐙': 'Octopus',
  '🐉': 'Dragon',
  '🦏': 'Rhino',
};

export default emojiNames;
