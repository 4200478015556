import { GameMetaData } from '../types';
import McMindNoText from './assets/mcmind-notext.webp';
import McMindText from './assets/mcmind-text.webp';

const McMindMetaData: GameMetaData = {
  name: 'So You Think You Can Count',
  cardColor: '#337b94',
  cardImage: McMindText,
  tagline: 'Teamwork • Communication',
  coverArt: McMindNoText,
  howToPlay: 'Work together to count as high as you can... without talking.',
  youWillNeed: [],
  minPlayers: 2,
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10m',
    },
    {
      emoji: '👥',
      text: '2+',
    },
    {
      emoji: '🤫',
      text: 'Silent',
    },
  ],
};

export default McMindMetaData;
