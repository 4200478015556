import { Grid, GridProps } from '@chakra-ui/react';

interface McGridProps extends GridProps {
  c?: string;
  r?: string;
}

const McGrid: React.FC<McGridProps> = ({
  children,
  c = '1fr',
  r = '1fr',
  ...props
}) => {
  return (
    <Grid
      className="McGrid" /* add className for easier debugging in DOM tree */
      templateColumns={c}
      templateRows={r}
      h="100%"
      w="100%"
      {...props}
    >
      {children}
    </Grid>
  );
};

export default McGrid;
