import { useCallback, useRef } from 'react';
import emojiNames from '@common/emojiNames';
import IframeInput from '@components/IframeInput';
import { useSendRoomMessage } from '@/connection/hooks';
import { useCosmetic, usePlayerName, useSetPlayerName } from '@/store/store';

function ProfileNameInput() {
  const sendMessage = useSendRoomMessage();
  const cosmetic = useCosmetic();
  const playerName = usePlayerName();
  const setPlayerName = useSetPlayerName();
  const emojiName = emojiNames[cosmetic.emoji];
  const doesUserHaveDefaultName = playerName === emojiName;

  // cache this, since it's only used as a default value for the input
  // and if we don't cache it, the IFrameInput will re-render every time
  // the user types a character.
  const defaultValue = useRef(
    doesUserHaveDefaultName ? '' : playerName
  ).current;

  const onChangeNameField: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const name = event.target.value.trim() || emojiName;
        setPlayerName(name);
        sendMessage({ type: 'SelectName', name });
      },
      [sendMessage, emojiName, setPlayerName]
    );

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback((event) => {
      if (event.key === 'Enter') {
        event.currentTarget.blur();
      }
    }, []);

  return (
    <IframeInput
      type="text"
      placeholder={emojiName}
      defaultValue={defaultValue}
      onKeyDown={handleKeyDown}
      onChange={onChangeNameField}
    />
  );
}

export default ProfileNameInput;
