import { Tag } from '@chakra-ui/react';
import McFlex from '@components/McFlex/McFlex';

export const EarlyAccessBadge = () => (
  <McFlex>
    <Tag
      sx={{
        '--badge-color': 'var(--chakra-colors-MagicWhite) !important',
      }}
      size="sm"
      color="MagicWhite"
      borderRadius="full"
      width="auto"
      variant="outline"
      fontFamily="Greycliff CF"
      fontWeight="bold"
    >
      BETA
    </Tag>
  </McFlex>
);
