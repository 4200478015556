import { useCallback } from 'react';
import { RoomMessage } from '@common/games/Room/messages';
import { AnyMessage } from '@common/types/messages';
import RoomConnection from './RoomConnection';
import { useScopes } from '@/hooks';

export function useRoomConnection(): RoomConnection {
  return RoomConnection.getInstance();
}

export function useSendRoomMessage(): (message: RoomMessage) => void {
  const roomConnection = useRoomConnection();
  return useCallback(
    (message: RoomMessage) => {
      roomConnection.sendMessage({
        scopePath: ['Room'],
        ...message,
      });
    },
    [roomConnection]
  );
}

export function useSendMessage<T extends AnyMessage>(): (message: T) => void {
  const roomConnection = useRoomConnection();
  const scopes = useScopes();

  return useCallback(
    (message: T): void => {
      roomConnection.sendMessage({ scopePath: scopes, ...message });
    },
    [scopes, roomConnection]
  );
}
