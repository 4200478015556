import emojiNames from '@common/emojiNames';
import { Cosmetic } from '@common/types/player';
import colors from '@components/ColorPicker/colors';
import emojis, { AllowedEmoji } from '@components/EmojiPicker/emojis';
import _ from 'lodash';

type Emoji = Cosmetic['emoji'];

// Player feedback:
// "take out gorilla, pig, monkey, cow random options (some ppl don’t like to be
// called those things — we did it for moonlight too)"
// See: https://github.com/magic-circle-studio/magiccircle.gg/issues/380
const potentiallyProblematicEmojis: AllowedEmoji[] = [
  '🦍',
  '🐷',
  '🐮',
  '🐵',
  '😈',
];

function randomIdentity(emojisInUse: Array<Emoji>): [string, Cosmetic] {
  // Get all emojis that are not used by existing players.
  const allNonProblematicEmojis = _.without(
    emojis,
    ...potentiallyProblematicEmojis
  );
  const unusedEmojis = _.without(allNonProblematicEmojis, ...emojisInUse);
  // If all emojis are used, just use all emojis (except problematic ones)
  const emojiPool =
    unusedEmojis.length === 0 ? allNonProblematicEmojis : unusedEmojis;
  const emoji = _.sample(emojiPool) ?? '👤';
  const name = emojiNames[emoji] ?? 'person';
  const color = _.sample(colors) ?? '#ffffff';
  return [name, { emoji, color }];
}

export default randomIdentity;
