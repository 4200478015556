import colors from 'src/theme/colors';
import { GameMetaData } from '../types';
import TrebekCoverArt from './assets/cover.webp';
import TrebekCard from './assets/cover.webp';

const TrebekMetaData: GameMetaData = {
  name: 'Listen Up!',
  cardColor: colors.LightPurple,
  cardImage: TrebekCard,
  coverArt: TrebekCoverArt,
  tagline: 'Storytelling • Trivia • Microphone',
  // tagline: 'Tell stories, quiz your friends. May the best listener win.',
  howToPlay: (
    <>
      Take turns telling stories with your mic on. Then, AI will generate
      questions based on your stories. Pay attention and quiz your friends!
    </>
  ),
  youWillNeed: [
    {
      emoji: '👂',
      text: 'A quiet-ish place to play',
    },
    {
      emoji: '😎',
      text: 'Interesting stories to tell',
    },
  ],
  quickBits: [
    {
      emoji: '⏱️',
      text: '5-10m',
    },
    {
      emoji: '👥',
      text: '2+',
    },
    {
      emoji: '🎤',
      text: 'Talking',
    },
  ],
  minPlayers: 2,
};

export default TrebekMetaData;
