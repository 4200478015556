import React, { useEffect, useRef } from 'react';
import { Box, Text, useToast } from '@chakra-ui/react';
import { PlayerId } from '@common/types/player';
import McFlex from '@components/McFlex/McFlex';
import PlayerToken from '@components/PlayerToken/PlayerToken';
import McCircle from '@/components/McCircle/McCircle';
import {
  useOpenDrawer,
  usePlayerId,
  usePlayersWithoutLeavers,
} from '@/store/store';

const startStacking = 3;

const PlusButton: React.FC = () => {
  return (
    <Text variant="Bogart" fontSize="50px" color="MagicWhite" fontWeight={800}>
      +
    </Text>
  );
};

const SystemJoinButton: React.FC = () => {
  const players = usePlayersWithoutLeavers();
  const myPlayerId = usePlayerId();
  const otherPlayers = players.filter((p) => p.id !== myPlayerId);
  const openDrawer = useOpenDrawer();
  const alreadyToastedPlayers = useRef<PlayerId[]>(players.map((p) => p.id));
  const toast = useToast();

  useEffect(() => {
    const newPlayers = otherPlayers.filter(
      (player) => !alreadyToastedPlayers.current.includes(player.id)
    );
    newPlayers.forEach((player) => {
      toast({
        title: `Someone joined the game!`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      alreadyToastedPlayers.current.push(player.id);
    });
  }, [otherPlayers, toast]);

  // Temp solution for width
  let width: number | undefined;
  if (players.length > 1) {
    width = 90;
  }
  if (players.length > 9) {
    width = 100;
  }

  return (
    <McFlex>
      <Box position="relative">
        <McCircle
          w={width}
          bg="MagicBlack"
          display="flex"
          justifyContent="flex-end"
          pr={3}
          pos="relative"
          top={0}
          left={0}
          onClick={() => openDrawer('players')}
          zIndex={1}
        >
          <Text variant="Bogart" size="md" color="MagicWhite" fontWeight={800}>
            {players.length}
          </Text>
        </McCircle>
        <McCircle
          aria-label="Plus"
          pos="absolute"
          top={0}
          bg="MagicBlack"
          zIndex={2}
          onClick={() => openDrawer('qr')}
        >
          <PlusButton />
        </McCircle>
        {otherPlayers.length > startStacking &&
          otherPlayers.map((player, idx) => (
            <Box
              key={player.id}
              position="absolute"
              top={0}
              left={`${40 + (otherPlayers.length - idx) * 8}px`}
            >
              <PlayerToken key={player.id} player={player} />
            </Box>
          ))}
      </Box>
      {otherPlayers.length <= startStacking && (
        <McFlex
          orient="left"
          ml={2}
          gap={1}
          onClick={() => openDrawer('players')}
        >
          {otherPlayers.map((player) => (
            <PlayerToken key={player.id} player={player} />
          ))}
        </McFlex>
      )}
    </McFlex>
  );
};

export default SystemJoinButton;
