enum WebSocketCloseCode {
  PlayerLeftVoluntarily = 3200,
  ConnectionSuperseded = 3300,
  HeartbeatExpired = 3400,
  PlayerKicked = 3500,
  PlayerInactive = 3600,
  VersionMismatch = 3700,
}

export default WebSocketCloseCode;
