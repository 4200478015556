import colors from 'src/theme/colors';
import { GameMetaData } from '../types';
// import YunomiLogo from './assets/YunomiLogo.svg';
import YunomiCoverArt from './assets/apples.png';

const YunomiMetaData: GameMetaData = {
  name: 'Yunomi',
  cardColor: colors.LimeGreen,
  cardImage: YunomiCoverArt,
  coverArt: YunomiCoverArt,
  tagline: '...but do ainoyu?',
  howToPlay: 'Coming soon...',
  youWillNeed: [
    {
      emoji: '🧟🧟‍♂️',
      text: 'Friends',
    },
  ],
  minPlayers: 3,
};

export default YunomiMetaData;
