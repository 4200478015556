import type { Base as LogtailStub } from '@logtail/core';
import type { Hub as SentryStub } from '@sentry/core';
import { getPlatform } from './getPlatform';

const colorCodes = {
  red: '\x1b[31m',
  yellow: '\x1b[33m',
  reset: '\x1b[0m',
};

type Attributes = Record<string, string | number | null>;

/**
 * Formats the log message based on the type of the input arguments.
 * If a single argument of type Error is passed, the function returns the Error instance.
 * If multiple arguments or arguments of other types are passed, the function converts them into strings.
 *
 * @param args - Any number of arguments of any type
 * @returns Formatted log message as a string, or an Error instance if a single Error argument is passed
 * @example
 * formatLogMessage('This is a message'); // Returns: 'This is a message'
 * formatLogMessage({ a: 1, b: 2 }); // Returns: '{"a":1,"b":2}'
 * formatLogMessage(new Error('This is an error')); // Returns: [Error: This is an error]
 * formatLogMessage('Multiple', 'arguments', { a: 1, b: 2 }, new Error('This is an error')); // Returns: 'Multiple arguments {"a":1,"b":2} This is an error'
 */
function formatLogMessage(...args: unknown[]): Error | string {
  if (args.length === 1 && args[0] instanceof Error) {
    return args[0] as Error;
  }

  return args
    .map((arg) => {
      if (arg instanceof Error) {
        // If Error instance, return its message and full stack trace.
        return `${arg.message}\n${arg.stack}`;
      } else if (typeof arg === 'object') {
        // If object, stringify it.
        try {
          return JSON.stringify(arg, null, 2);
        } catch {
          return arg?.toString();
        }
      } else {
        // If any other type, convert it to a string.
        return String(arg);
      }
    })
    .join(' ');
}

/**
 * Logger class for structured logging using @logtail/js.
 * **Always** logs to console, even if Logtail initialization fails.
 */
class Logger {
  private logtail?: LogtailStub;
  private sentry?: SentryStub;
  private attributes: Attributes = {};
  private platform = getPlatform();
  private readonly logLevel: string;
  private originalConsoleLogMethods = {
    log: console.log,
    info: console.info,
    warn: console.warn,
    error: console.error,
    debug: console.debug,
  };

  /**
   * Constructor for the Logger class.
   * Accepts optional Logtail and Sentry clients. If provided, logs will be sent to Logtail and Sentry.
   * If the Logtail or Sentry client is not provided or cannot be initialized, logs will only be sent to the console.
   *
   * @param logtailClient - An instance of Logtail client
   * @param sentryClient - An instance of Sentry client
   * @example
   * const logger = new Logger(); // Logs will only be sent to console
   * const logger = new Logger(logtailClient); // Logs will be sent to Logtail and console
   * const logger = new Logger(logtailClient, sentryClient); // Logs will be sent to Logtail, Sentry and console
   */
  constructor(
    logtailClient?: LogtailStub,
    sentryClient?: SentryStub,
    logLevel?: string
  ) {
    this.logtail = logtailClient;
    this.sentry = sentryClient;
    this.logLevel = logLevel || 'info';
    if (this.platform === 'browser' && sentryClient) {
      throw new Error(
        'Logger + Sentry is not supported in the browser. It is assumed that the Sentry browser SDK is used instead.'
      );
    }

    if (this.platform === 'other' && this.logLevel !== 'debug') {
      console.log(
        "🙈 debug-level logs will not be printed to console. Set the LOG_LEVEL environment variable to 'debug' if you want to see them. Note: debug logs will still be sent to Logtail in environments configured to do so."
      );
    }
  }

  private get roomIdPrefix(): string | undefined {
    if (this.platform !== 'browser') {
      const roomId = (self as { roomId?: string }).roomId;
      return roomId ? `[${roomId}]` : '[UNKNOWN ROOM ID]';
    }
  }

  /**
   * Logs a message at 'info' level. An alias for {@link Logger.info}
   * The message is formatted using the formatLogMessage function.
   *
   * @param args - Any number of arguments of any type
   * @example
   * info('This is some info');
   */
  public log = this.info;

  /**
   * Logs a message at 'info' level.
   * The message is formatted using the formatLogMessage function.
   *
   * @param args - Any number of arguments of any type
   * @example
   * info('This is some info');
   */
  public info(...args: unknown[]) {
    const msg = formatLogMessage(...args);
    // The browser is good at displaying logs (e.g. complex objects) via DevTools, so we don't
    // format the message
    if (this.platform === 'browser') {
      this.originalConsoleLogMethods.info(...args);
    } else {
      this.originalConsoleLogMethods.info(this.roomIdPrefix, msg);
    }
    this.logtail?.info(msg, this.attributes);
  }

  /**
   * Logs a message at 'debug' level.
   * The message is formatted using the formatLogMessage function.
   *
   * @param args - Any number of arguments of any type
   * @example
   * debug('This is some debug info');
   */
  public debug(...args: unknown[]) {
    const msg = formatLogMessage(...args);
    // The browser is good at displaying logs (e.g. complex objects) via DevTools, so we don't
    // format the message
    if (this.platform === 'browser') {
      this.originalConsoleLogMethods.debug(...args);
    } else {
      if (this.logLevel === 'debug') {
        this.originalConsoleLogMethods.debug(this.roomIdPrefix, msg);
      }
    }
    this.logtail?.debug(msg, this.attributes);
  }

  /**
   * Logs a message at 'warn' level.
   * The message is formatted using the formatLogMessage function.
   *
   * @param args - Any number of arguments of any type
   * @example
   * warn('This is a warning');
   */
  public warn(...args: unknown[]) {
    const msg = formatLogMessage(...args);
    // The browser is good at displaying logs (e.g. complex objects) via DevTools, so we don't
    // format the message
    if (this.platform === 'browser') {
      this.originalConsoleLogMethods.warn(...args);
    } else {
      this.originalConsoleLogMethods.warn(
        colorCodes.yellow + this.roomIdPrefix + ' [WARNING]',
        msg,
        colorCodes.reset
      );
    }
    this.sentry?.captureMessage(String(msg), 'warning');
    this.logtail?.warn(msg, this.attributes);
  }

  public error(...args: unknown[]) {
    const msg = formatLogMessage(...args);
    // The browser is good at displaying logs (e.g. complex objects) via DevTools, so we don't
    // format the message
    if (this.platform === 'browser') {
      this.originalConsoleLogMethods.error(...args);
    } else {
      this.originalConsoleLogMethods.error(
        colorCodes.red + this.roomIdPrefix + ' [ERROR]',
        msg,
        colorCodes.reset
      );
    }
    this.sentry?.captureException(msg, {
      originalException: msg,
    });
    this.logtail?.error(msg, this.attributes);
  }

  /**
   * Sets the given attributes, overwriting any existing attribute with the same key.
   * If you plan to un-set these attributes later, consider using {@link Logger.pushAttributes} and {@link Logger.popAttributes}.
   *
   * @param attributes - The attributes to set
   * @example
   * setAttributes({ attr1: 'value1', attr2: 'value2' });
   */
  public setAttributes(attributes: Attributes) {
    this.attributes = Object.assign({}, this.attributes, attributes);
  }

  /**
   * This is a small convenience wrapper around {@link Logger.setAttributes}.
   * It adds the given attributes and returns an array of the attribute keys that were added.
   * This makes it easier to pop the attributes later using {@link Logger.popAttributes}.
   *
   * @param attributes - The attributes to add
   * @returns An array of the attribute keys that were added
   * @example
   * const logger = new Logger();
   * logger.pushAttributes({ attr1: 'value1', attr2: 'value2' }); // Returns: ['attr1', 'attr2']
   */
  public pushAttributes(attributes: Attributes): string[] {
    this.setAttributes(attributes);
    return Object.keys(attributes);
  }

  /**
   * Removes the attributes with the given keys, assuming you previously added them using {@link Logger.pushAttributes}.
   *
   * @param attributeKeys - The keys of the attributes to remove
   * @example
   * const logger = new Logger();
   * const attributes = logger.pushAttributes({ attr1: 'value1', attr2: 'value2' });
   * logger.popAttributes(attributes); // same as: logger.popAttributes(['attr1', 'attr2']);
   */
  public popAttributes(attributeKeys: string[]) {
    for (const attribute of attributeKeys) {
      delete this.attributes[attribute];
    }
  }

  /**
   * Patches the console methods (log, info, warn, error) to use the corresponding methods of the Logger class.
   * This ensures that all console logs are also sent to Logtail (if initialized).
   */
  public monkeyPatchConsole() {
    if (this.platform !== 'browser') {
      throw new Error('Console patching is only supported in the browser');
    }

    const consoleMethodsToPatch = Object.keys(
      this.originalConsoleLogMethods
    ) as Array<keyof typeof this.originalConsoleLogMethods>;

    consoleMethodsToPatch.forEach((method) => {
      console[method] = (...args: unknown[]) => {
        this[method](...args);
      };
    });
  }
}

export default Logger;
