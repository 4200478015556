import React, { LazyExoticComponent } from 'react';

// prettier-ignore
const gameViews = {
  Trebek: React.lazy(() => import('../games/Trebek/TrebekView')),
  Avocado: React.lazy(() => import('../games/Avocado/AvocadoView')),
  McMind: React.lazy(() => import('../games/McMind/McMindView')),
  SolidStare: React.lazy(() => import('../games/SolidStare/SolidStareView')),
  Yunomi: React.lazy(() => import('../games/Yunomi/YunomiView')),
  NoseOlympics: React.lazy(() => import('../games/NoseOlympics/NoseOlympicsView')),
  TellTaleHeart: React.lazy(() => import('../games/TellTaleHeart/TellTaleHeartView')),
  Lobby: React.lazy(() => import('../games/Lobby/LobbyView')),
} satisfies { [k: string]: LazyExoticComponent<React.FC> };

export type GameName = keyof typeof gameViews;

/**
 * GameNames is a constant that maps each game name to itself.
 * It is an object where the keys are the game names and the values are the same game names.
 * This is useful when you want to ensure type safety with game names.
 * Think of it like an enum.
 */
export const GameNames = Object.fromEntries(
  Object.entries(gameViews).map(([gameName]) => [gameName, gameName])
) as { [K in GameName]: K };

export default gameViews;
