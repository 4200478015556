import Player from '@common/types/player';

const defaultPlayer: Player = {
  id: '',
  name: '',
  hasLeft: false,
  lastSeen: 0,
  cosmetic: {
    emoji: '',
    color: '',
  },
};

export default defaultPlayer;
