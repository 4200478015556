import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    fontFamily: 'Greycliff CF',
    fontWeight: 700,
    textAlign: 'center',
    width: '250px',
    maxW: '90%',
    py: '10px',
    px: '20px',
  },
});

const InputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    mcbase: {
      field: {
        // backgroundColor: 'MagicBlack',
        border: '3px solid white',
        borderColor: 'MagicWhite',
        color: 'MagicWhite',
        borderRadius: '12px',
        fontSize: '24px',
        height: 'auto',
      },
    },
  },
  defaultProps: {
    variant: 'mcbase',
  },
});

export default InputTheme;
