import { AddIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { RoomData } from '@common/games/Room/types';
import { IState } from '@common/types/state';
import McFlex from '@components/McFlex/McFlex';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { orderBy } from 'lodash';
import { useShiftKeyPressed } from './useShiftKeyPressed';
import { API_ROOT } from '@/environment';

async function getStateDump(): Promise<IState<RoomData>> {
  const dumpStateUrl = API_ROOT + '/debug/state';
  const response = await fetch(dumpStateUrl);
  const data = await response.json();
  return data;
}

async function loadStateDump(checkpoint: Checkpoint) {
  const loadStateUrl = API_ROOT + '/debug/state';
  const response = await fetch(loadStateUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(checkpoint.state),
  });
  return response;
}

function getScopeChainFromState(state: IState<unknown>): string[] {
  let leafState: IState<unknown> | null = state;
  const scopes: string[] = [];
  while (leafState) {
    scopes.push(leafState.scope);
    leafState = leafState.child;
  }
  return scopes;
}

type Checkpoint = {
  id: string;
  name?: string;
  dateCaptured: string;
  state: IState<RoomData>;
};

const checkpointsAtom = atomWithStorage<Checkpoint[]>('checkpoints', []);
export function CheckpointManager({
  onCheckpointSelected,
}: {
  onCheckpointSelected: () => void;
}) {
  const [checkpoints, setCheckpoints] = useAtom(checkpointsAtom);
  const isShiftKeyPressed = useShiftKeyPressed();

  const onCreateCheckpoint = async () => {
    const state = await getStateDump();
    const leafScope = getScopeChainFromState(state);
    const scopeWithoutRoom = leafScope.slice(1);
    const defaultName = scopeWithoutRoom.join('.');
    const checkpointName = prompt('Enter checkpoint name', defaultName);
    if (checkpointName === null) {
      console.warn('Checkpoint creation cancelled');
      return;
    }
    const checkpoint = {
      id: crypto.randomUUID(),
      name: checkpointName || undefined,
      dateCaptured: new Date().toISOString(),
      state: state,
    };
    setCheckpoints([...checkpoints, checkpoint]);
  };

  async function onSelectCheckpoint(checkpointId: string) {
    if (isShiftKeyPressed) {
      setCheckpoints(checkpoints.filter(({ id }) => id !== checkpointId));
      return true;
    }

    const selectedCheckpoint = checkpoints.find(
      ({ id }) => id === checkpointId
    );
    if (!selectedCheckpoint) {
      console.error(`Checkpoint not found: ${checkpointId}`);
      return;
    }
    console.log(selectedCheckpoint);
    await loadStateDump(selectedCheckpoint);
    onCheckpointSelected();
  }
  const handleLongPress = (checkpointId: string) => {
    const selectedCheckpoint = checkpoints.find(
      ({ id }) => id === checkpointId
    );
    const confirmDelete = window.confirm(
      `Delete checkpoint ${selectedCheckpoint?.name}?`
    );
    if (confirmDelete) {
      setCheckpoints(checkpoints.filter(({ id }) => id !== checkpointId));
    }
  };

  let pressTimer: number;
  const handleTouchStart = (checkpointId: string) => {
    pressTimer = setTimeout(() => handleLongPress(checkpointId), 800);
  };
  const handleTouchEnd = () => {
    clearTimeout(pressTimer);
  };

  return (
    <div>
      <McFlex gap="2">
        <Button variant="solid" onClick={onCreateCheckpoint}>
          <AddIcon />
        </Button>
        <Menu isOpen={checkpoints.length === 0 ? false : undefined}>
          <MenuButton
            as={Button}
            width="150px"
            isDisabled={checkpoints.length === 0}
          >
            Jump to...
          </MenuButton>
          <MenuList>
            {orderBy(
              checkpoints,
              [(checkpoint) => new Date(checkpoint.dateCaptured).getTime()],
              ['desc']
            ).map((checkpoint) => (
              <MenuItem
                key={checkpoint.id}
                onTouchStart={() => handleTouchStart(checkpoint.id)}
                onTouchEnd={handleTouchEnd}
                onClick={() => onSelectCheckpoint(checkpoint.id)}
                closeOnSelect={false}
              >
                {checkpoint.name}&nbsp;&nbsp;
                <span style={{ opacity: 0.5 }}>
                  {new Date(checkpoint.dateCaptured).toLocaleString()}
                </span>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </McFlex>
    </div>
  );
}
