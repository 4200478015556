import { ThemeOverride, defineStyleConfig } from '@chakra-ui/react';

// https://chakra-ui.com/docs/styled-system/customize-theme

const McMindTheme: ThemeOverride = {
  components: {
    Button: defineStyleConfig({
      // The styles all button have in common
      baseStyle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        borderRadius: 'base', // <-- border radius is same for all variants and sizes
      },
      // Two variants: outline and solid
      variants: {
        solid: {
          bg: 'BabyBlue',
          borderRadius: 6,
          px: 5,
          py: 7,
          _hover: {
            bg: 'BabyBlue',
          },
          _active: {
            bg: 'MagicBlue',
          },
        },
        // outline: {
        //   border: '2px solid',
        //   borderColor: 'purple.500',
        //   color: 'purple.500',
        // },
        // solid: {
        //   bg: 'MagicWhite',
        //   color: 'MagicBlack',
        //   fontFamily: 'GreyCliff CF',
        //   _hover: {
        //     bg: 'MagicWhite',
        //     color: 'MagicBlack',
        //   },
        //   _active: {
        //     bg: 'whiteAlpha.700',
        //   },
        // },
        // primary: {
        //   bg: 'MagicPurple',
        //   color: 'MagicWhite',
        //   fontFamily: 'GreyCliff CF',
        //   _hover: {
        //     bg: 'MagicPurple',
        //     color: 'MagicWhite',
        //   },
        //   _disabled: {
        //     // color becomes transparent when disabled and clicked without !important
        //     bg: 'MagicPurple !important',
        //     opacity: '0.4 !important',
        //   },
        //   _active: {
        //     opacity: 0.7,
        //   },
        // },
        // positive: {
        //   bg: 'DarkGreen',
        //   color: 'MagicWhite',
        //   fontFamily: 'GreyCliff CF',
        //   _hover: {
        //     bg: 'DarkGreen',
        //     color: 'MagicWhite',
        //   },
        //   _active: {
        //     opacity: 0.7,
        //   },
        //   _disabled: {
        //     pointerEvents: 'none',
        //   },
        // },
        // negative: {
        //   bg: 'MagicRed',
        //   color: 'MagicWhite',
        //   fontFamily: 'GreyCliff CF',
        //   _hover: {
        //     bg: 'MagicRed',
        //     color: 'MagicWhite',
        //   },
        //   _active: {
        //     opacity: 0.7,
        //   },
        //   _disabled: {
        //     pointerEvents: 'none',
        //   },
        // },
      },
      // The default size and variant values
      defaultProps: {
        size: 'md',
        variant: 'solid',
      },
    }),
  },
};

export default McMindTheme;
