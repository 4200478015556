import StockAlwaysDarkChakraProvider from '@components/StockDarkThemeChakraProvider';
import McDevTools from './McDevTools';
import { useConfig } from '@/config';

const MagicCircleDevTools = () => {
  const showTools = useConfig().root_MagicCircleDevTools;

  if (!showTools) {
    return null;
  }

  return (
    <StockAlwaysDarkChakraProvider>
      <McDevTools />
    </StockAlwaysDarkChakraProvider>
  );
};

export default MagicCircleDevTools;
