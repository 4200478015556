import emojiNames from '@common/emojiNames';
import EmojiPicker from '@components/EmojiPicker/EmojiPicker';
import { useSendRoomMessage } from '@/hooks';
import {
  useCosmetic,
  usePlayerNameMatchesTheirEmoji,
  useSetCosmetic,
  useSetPlayerName,
} from '@/store/store';

function ProfileEmojiPicker() {
  const cosmetic = useCosmetic();
  const setCosmetic = useSetCosmetic();
  const setPlayerName = useSetPlayerName();
  const sendMessage = useSendRoomMessage();
  // we use this hook because it does NOT cause this component to re-render when
  // the player's name changes, but only when the status of whether their name
  // matches their emoji changes.
  const doesNameMatchEmoji = usePlayerNameMatchesTheirEmoji();

  const onClickEmoji = (emoji: string) => {
    // If the user's name matches their emoji, and they've changed their emoji,
    // then change their name to the default name for that emoji.
    if (doesNameMatchEmoji) {
      const newDefaultName = emojiNames[emoji];
      setPlayerName(newDefaultName);
      sendMessage({ type: 'SelectName', name: newDefaultName });
    }
    setCosmetic({ ...cosmetic, emoji });
    sendMessage({ type: 'SelectEmoji', emoji });
  };

  return <EmojiPicker onClick={onClickEmoji} selectedEmoji={cosmetic.emoji} />;
}

export default ProfileEmojiPicker;
