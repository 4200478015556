import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// Tabs not looking right? Make sure you:
// 1. set your <Tabs> component's variant prop to "unstyled"
// 2. Add a <TabIndicator/> component as a child of your <Tabs> component,
//    between the Tabs' <TabList> and <TabPanels> components
const baseStyle = definePartsStyle({
  tab: {
    // color: 'MagicWhite', // TODO: adopt textStyle to get this to work
    textStyle: 'content',
    fontFamily: 'GreyCliff CF',
    fontWeight: 'extrabold',
    userSelect: 'none',
  },
  indicator: {
    height: '3px',
    borderRadius: '3px',
    bg: 'MagicWhite',
  },
});

const TabsTheme = defineMultiStyleConfig({ baseStyle });

export default TabsTheme;
