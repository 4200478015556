import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { RoomData } from '@common/games/Room/types';
import gameMetaDatas from '@/games/gameMetaDatas';
import { useData, useSendRoomMessage } from '@/hooks';

const GameStartingModal: React.FC = () => {
  const { gameStartTimer } = useData<RoomData>();
  const { gameName, secondsUntilGameStarts } = gameStartTimer;
  const sendMessage = useSendRoomMessage();

  const metaData = gameMetaDatas[gameName];
  if (!metaData) return null;

  const onClickStop = () => {
    sendMessage({ type: 'CancelGameStartTimer' });
  };

  return (
    <Modal
      isOpen={secondsUntilGameStarts > 0}
      onClose={() => console.log('Close with Stop button')}
    >
      <ModalOverlay />
      <ModalContent w="90%" borderRadius={14}>
        <ModalHeader pb={0}>
          <Heading size="2xl" color="MagicBlack" textAlign="center" mt={2}>
            Game Starts in
          </Heading>
        </ModalHeader>
        <ModalBody bg="MagicWhite" borderRadius={14}>
          <Flex flexDir="column" align="center" justify="center" height="auto">
            <Heading fontSize="64px" color="MagicBlack" textAlign="center">
              {secondsUntilGameStarts}
            </Heading>
            <Button
              variant="outlineInverse"
              size="md"
              my={3}
              onClick={onClickStop}
            >
              cancel
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GameStartingModal;
