import WebSocketCloseCode from '@common/WebSocketCloseCode';
import { RemoteOperationError } from '@common/errors';
import { RoomMessage } from '@common/games/Room/messages';
import { RoomData } from '@common/games/Room/types';
import {
  ClientToServerMessage,
  ServerToClientMessage,
} from '@common/types/messages';
import { IState } from '@common/types/state';
import { Immutable } from '@common/utils';
import { applyReducer } from 'fast-json-patch';
import { getDefaultStore } from 'jotai';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { configAtom } from '../config';
import { API_ROOT, GitSha } from '../environment';
import {
  cosmeticAtom,
  playerIdAtom,
  playerNameAtom,
  stateAtom,
} from '../store/store';
import defaultPlayer from '@/constants/default-player';
import randomIdentity from '@/utils/randomIdentity';

// async function debug_sendAudioToWormholeServer(blob: Blob) {
//   const response = await fetch(
//     'https://wormhole-avi.magiccircle.dev/saveBinary',
//     {
//       method: 'POST',
//       body: blob,
//     }
//   );
//   if (!response.ok) {
//     throw new Error(`HTTP error! status: ${response.status}`);
//   }
//   console.log(await response.text());
// }

type ReconnectionSuccess = {
  isConnected: true;
};

type ReconnectionFailure = {
  isConnected: false;
  numConsecutiveAttempts: number;
};

type ReconnectionState = ReconnectionSuccess | ReconnectionFailure;

type PendingBinaryMessage = {
  blob: Blob;
  kind: 'binary';
};

type PendingJsonMessage = {
  message: ClientToServerMessage;
  kind: 'object';
};

type PendingMessage = PendingBinaryMessage | PendingJsonMessage;

declare global {
  interface Window {
    MagicCircle_RoomConnection: RoomConnection;
  }
}

const permanentlyDisconnectedCloseCodes = [
  WebSocketCloseCode.ConnectionSuperseded,
  WebSocketCloseCode.PlayerKicked,
  WebSocketCloseCode.VersionMismatch,
] as const;

export type PermanentlyDisconnectedCloseCode =
  (typeof permanentlyDisconnectedCloseCodes)[number];

export default class RoomConnection {
  private currentWebSocket: WebSocket | null = null;
  private heartbeatInterval: number | undefined;
  private lastHeartbeatFromServer = Date.now();
  private roomState: Immutable<IState<RoomData>> | undefined;

  private readonly pendingMessages: PendingMessage[] = [];

  // A connection is "Supesceded" if the user has opened a new connection to the
  // same room. This can happen if the user opens the same room in multiple
  // tabs.
  // This is a weird and unsupported state, because we don't want to have
  // multiple connections for the same playerId in the same room.
  public onPermanentlyDisconnected?: (
    reason: PermanentlyDisconnectedCloseCode
  ) => void;

  private numConsecutiveReconnectionAttempts = 0;
  public onReconnectionAttempt?: (state: ReconnectionState) => void;

  private readonly rejoin: () => void;
  private get isDocumentHidden() {
    return document.visibilityState === 'hidden';
  }

  private constructor() {
    if (window.MagicCircle_RoomConnection) {
      throw new Error(
        'RoomConnection is a singleton. Use RoomConnection.getInstance() instead.'
      );
    }

    this.rejoin = _.throttle(
      () => {
        this.numConsecutiveReconnectionAttempts += 1;
        this.onReconnectionAttempt?.({
          isConnected: false,
          numConsecutiveAttempts: this.numConsecutiveReconnectionAttempts,
        });
        console.debug('rejoin()');
        this.join();
      },
      1000,
      { leading: false, trailing: true }
    );

    document.addEventListener('visibilitychange', () => {
      console.debug(
        `Document visibility changed to ${document.visibilityState}`
      );
    });
  }

  public static getInstance() {
    if (!window.MagicCircle_RoomConnection) {
      window.MagicCircle_RoomConnection = new RoomConnection();
    }
    return window.MagicCircle_RoomConnection;
  }

  private startHeartbeat() {
    this.lastHeartbeatFromServer = Date.now();
    console.debug('starting new heartbeat interval');
    this.heartbeatInterval = setInterval(() => {
      // if 10 seconds have passed since the last ping from the server
      const millisSinceLastHeartbeat =
        Date.now() - this.lastHeartbeatFromServer;
      if (!this.isDocumentHidden && millisSinceLastHeartbeat > 10_000) {
        console.info(
          `server heartbeat lost, ${millisSinceLastHeartbeat}ms elapsed`
        );
        // connection lost, initiate reconnection logic
        this.disconnect(WebSocketCloseCode.HeartbeatExpired);
        this.rejoin();
      }
    }, 1000); // check every second
  }

  public disconnect(closeCode?: WebSocketCloseCode) {
    this.stopHeartbeat();

    if (this.currentWebSocket) {
      this.currentWebSocket.onopen = null;
      this.currentWebSocket.onmessage = null;
      this.currentWebSocket.onclose = null;

      let reason: string | undefined;
      switch (closeCode) {
        case WebSocketCloseCode.ConnectionSuperseded: {
          reason = 'connection superseded';
          break;
        }
        case WebSocketCloseCode.PlayerKicked: {
          reason = 'player kicked';
          break;
        }
        case WebSocketCloseCode.VersionMismatch: {
          reason = `version mismatch (${GitSha})`;
          break;
        }
        case WebSocketCloseCode.HeartbeatExpired: {
          reason = `heartbeat expired`;
          break;
        }
        case WebSocketCloseCode.PlayerLeftVoluntarily: {
          reason = 'player left voluntarily';
          break;
        }
        default: {
          reason = undefined;
        }
      }

      // Only close the WebSocket if it's open, otherwise we'll get an error
      if (this.currentWebSocket.readyState === WebSocket.OPEN) {
        this.currentWebSocket.close(closeCode, reason);
      }
    }
  }

  private stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = undefined;
    }
  }

  public join() {
    // If an existing WebSocket connection is open, disconnect first
    this.disconnect();

    this.currentWebSocket = new WebSocket(getWebSocketUrl());

    // Attach event listeners
    this.currentWebSocket.onopen = this.onWebSocketOpen;
    this.currentWebSocket.onmessage = this.onWebSocketMessage;
    // Note: onclose can fire with or without onerror. So, we just ignore
    // onerror and handle all errors in onclose. Besides, onerror includes
    // no useful information about the error anyway.
    // More info: https://stackoverflow.com/a/40084550
    this.currentWebSocket.onclose = this.onWebSocketClose;
  }

  private readonly onWebSocketOpen = () => {
    console.debug('WebSocket opened');
    this.stopHeartbeat();
    this.startHeartbeat();
    // Clear the reconnection attempt counter now that we're connected
    this.numConsecutiveReconnectionAttempts = 0;
    this.onReconnectionAttempt?.({
      isConnected: true,
    });
    this.pendingMessages.forEach((message) => {
      if (message.kind === 'binary') {
        this.sendBinaryMessage(message.blob);
      } else {
        this.sendMessage(message.message);
      }
    });
    this.pendingMessages.length = 0;
  };

  private readonly onWebSocketMessage = (event: MessageEvent) => {
    // If we received a Ping message, we update the lastHeartbeatTime
    if (event.data === 'ping') {
      if (this.isDocumentHidden) {
        console.debug(
          'Received server ping, but document is hidden. Not sending pong.'
        );
      } else {
        this.currentWebSocket?.send('pong');
        console.debug('Received server ping, sending pong');
        this.lastHeartbeatFromServer = Date.now();
      }
    } else {
      const message: ServerToClientMessage = JSON.parse(event.data);
      console.debug(`received from server ${message.type} message`, message);
      this.onServerToClientMessage(message);
    }
  };

  private readonly onWebSocketClose = (event: CloseEvent) => {
    console.log(
      `WebSocket closed (${event.code}) reason: ${event.reason || 'none'}`
    );
    const isPermanentlyDisconnected =
      permanentlyDisconnectedCloseCodes.includes(event.code);

    if (isPermanentlyDisconnected) {
      this.onPermanentlyDisconnected?.(event.code);
      this.stopHeartbeat();
      return;
    }

    if (this.isDocumentHidden) {
      console.log(
        'WebSocket closed, but document is hidden. Not attempting to reconnect.'
      );
    } else {
      this.rejoin();
    }
  };

  public sendRoomMessage(message: RoomMessage) {
    this.sendMessage({ scopePath: ['Room'], ...message });
  }

  public sendMessage(message: ClientToServerMessage) {
    if (
      !this.currentWebSocket ||
      this.currentWebSocket.readyState !== WebSocket.OPEN
    ) {
      this.pendingMessages.push({ message, kind: 'object' });
      console.log(
        this.currentWebSocket,
        this.currentWebSocket?.readyState,
        RoomConnection.getInstance()
      );
      console.warn(
        'No websocket connection. Message will be sent later when connetion is established.',
        message
      );
      return;
    }
    console.log('sending ClientToServer message', message);

    this.currentWebSocket.send(JSON.stringify(message));
  }

  public sendBinaryMessage(blob: Blob) {
    // default chunk size is 512KiB
    // Note: CloudFlare Durable Objects imposes a max 1MiB limit on the size of
    // a single websocket message. So, we need to send the data in chunks.
    // See: https://developers.cloudflare.com/workers/platform/limits/#durable-objects
    const chunkSize: number = 1024 * 512;

    const reader = new FileReader();
    reader.onloadend = () => {
      if (
        !this.currentWebSocket ||
        this.currentWebSocket.readyState !== WebSocket.OPEN
      ) {
        this.pendingMessages.push({ blob, kind: 'binary' });
        console.warn(
          'No websocket connection. Message will be sent later when connetion is established.'
        );
        return;
      }

      const arrayBuffer = reader.result as ArrayBuffer;

      // Prepare header: convert total size to a 32-bit integer ArrayBuffer
      const totalSizeBuffer = new Uint32Array([arrayBuffer.byteLength]).buffer;

      // Send header first (total size of the data)
      this.currentWebSocket.binaryType = 'arraybuffer';
      this.sendRoomMessage({ type: 'WillSendBinaryDoNotDisconnectMe' });
      console.log(`sending binary message of size ${blob.size}`);
      this.currentWebSocket.send(totalSizeBuffer);

      // Send arrayBuffer in chunks
      for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
        let end = i + chunkSize;

        // Make sure we don't exceed the buffer size
        if (end > arrayBuffer.byteLength) {
          end = arrayBuffer.byteLength;
        }

        const chunk = arrayBuffer.slice(i, end);
        this.currentWebSocket.send(chunk);
      }

      this.sendRoomMessage({ type: 'FinishedSendingBinary' });
    };
    reader.readAsArrayBuffer(blob);
  }

  private onServerToClientMessage = (message: ServerToClientMessage) => {
    const { get, set } = getDefaultStore();
    const messageType = message.type;
    switch (messageType) {
      case 'Welcome': {
        set(playerIdAtom, message.playerId);
        this.roomState = message.fullState as Immutable<IState<RoomData>>;
        set(stateAtom, _.cloneDeep(this.roomState));
        mixpanel.track('Joined room');

        // If the player has not set a name or cosmetic, set them to intelligent
        // defaults — a random name and cosmetic, with the cosmetic NOT matching
        // another existing player in the room.
        if (
          get(playerNameAtom) === defaultPlayer.name &&
          _.isEqual(get(cosmeticAtom), defaultPlayer.cosmetic)
        ) {
          // Generate a random name and cosmetic for the player to use as default values.
          // These default values will get persisted to localStorage when the user joins
          // a room.
          const [randomName, randomCosmetic] = randomIdentity(
            message.emojisInUse
          );
          set(cosmeticAtom, randomCosmetic);
          set(playerNameAtom, randomName);
        }

        // Write the cosmetic to local storage to persist it across sessions.
        // Tell the server to set the initial player data (name and cosmetic)
        this.sendRoomMessage({
          type: 'SetInitialPlayerData',
          cosmetic: get(cosmeticAtom),
          name: get(playerNameAtom),
        });
        break;
      }
      case 'PartialState': {
        if (!this.roomState) {
          throw new Error(
            'Received PartialState message before Welcome message'
          );
        }
        const prevState = _.cloneDeep(this.roomState);
        const nextState = message.patches.reduce(applyReducer, prevState);
        this.roomState = nextState;
        const deepCloneOfNextRoomState = _.cloneDeep(this.roomState);
        set(stateAtom, deepCloneOfNextRoomState);
        break;
      }
      case 'ServerErrorMessage': {
        throw new RemoteOperationError(message.error, message.messageContext);
      }
      case 'Config': {
        set(configAtom, message.config);
        break;
      }
      default: {
        messageType satisfies never;
        console.error(`Unknown message type '${messageType}'`);
      }
    }
  };
}

function getWebSocketUrl(): string {
  const { get } = getDefaultStore();
  const previousPlayerId = get(playerIdAtom) || undefined;
  const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
  const url = new URL(`${protocol}//${location.host}${API_ROOT}`);
  if (previousPlayerId) {
    url.searchParams.append('playerId', previousPlayerId);
  }
  if (GitSha) {
    url.searchParams.append('version', GitSha);
  }
  return url.toString();
}
