import Environment from '@common/Environment';
import { getRoomIdFromCurrentUrl } from './utils';
const roomId = getRoomIdFromCurrentUrl();

export const GitSha: string | undefined = import.meta.env.VITE_GIT_SHA;
export const environment: Environment =
  import.meta.env.VITE_MC_ENVIRONMENT || 'Local';
export const API_ROOT = `/api/rooms/${roomId}`;

console.log(`Environment: ${environment}. Vite mode: ${import.meta.env.MODE}`);
