const ROOM_REGEX = /\/(api\/rooms\/|r\/)([^/]+)/;

export default function getSantizedRoomIdFromUrl(
  url: string | undefined
): string | null {
  if (!url) {
    return null;
  }
  const { pathname } = new URL(url);
  const roomId = pathname.match(ROOM_REGEX)?.[2];
  if (!roomId) {
    return null;
  }
  return roomId.toUpperCase();
}
