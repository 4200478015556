import { Suspense } from 'react';
import { useChildScopeName } from 'src/hooks';
import LoadingScreen from './LoadingScreen';
import McChakraProvider from './McChakraProvider';
import Scope from './Scope';

type ViewRouterProps = {
  views: Record<string, React.FC>;
};

const ViewRouter: React.FC<ViewRouterProps> = ({ views }) => {
  const childScope = useChildScopeName();
  if (!childScope) {
    return null;
  }

  const View = views[childScope];

  if (!View) {
    throw new Error(`No view found for scope: ${childScope}`);
  }

  return (
    <Scope scope={childScope}>
      <McChakraProvider>
        <Suspense fallback={<LoadingScreen />}>
          <View />
        </Suspense>
      </McChakraProvider>
    </Scope>
  );
};

export default ViewRouter;
