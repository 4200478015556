import { useEffect, useState } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import Emoji from '@components/Emoji/Emoji';
import McCircle from '@components/McCircle/McCircle';
import emojis, { isAllowedEmoji } from './emojis';

interface EmojiPickerProps {
  onClick: (emoji: string) => void;
  selectedEmoji?: string;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({
  onClick,
  selectedEmoji,
}) => {
  const [circlePos, setCirclePos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (selectedEmoji) {
      if (!isAllowedEmoji(selectedEmoji)) {
        console.error(
          `Invalid emoji: ${selectedEmoji} is not one of the allowed emojis: ${emojis}`
        );
        return;
      }
      const index = emojis.indexOf(selectedEmoji);
      const row = Math.floor(index / 6);
      const col = index % 6;
      // top left position of cell + half cell widthOrHeight - half circle widthOrHeight
      setCirclePos({
        x: col * 50 + 50 / 2 - 45 / 2,
        y: row * 50 + 50 / 2 - 49 / 2,
      });
    }
  }, [selectedEmoji]);

  return (
    <Grid
      templateColumns="repeat(6, 50px)"
      gridAutoRows="50px"
      justifyItems="center"
      alignItems="center"
      pos="relative"
    >
      {selectedEmoji && (
        <McCircle
          pos="absolute"
          left={`${circlePos.x}px`}
          top={circlePos.y}
          borderWidth="3px"
        />
      )}
      {emojis.map((emoji) => (
        <Box key={emoji}>
          <Emoji emoji={emoji} size="sm" onClick={() => onClick(emoji)} />
        </Box>
      ))}
    </Grid>
  );
};

export default EmojiPicker;
