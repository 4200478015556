import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    McDefault: {
      dialog: {
        bg: 'MagicWhite',
        textColor: 'MagicBlack',
        borderRadius: '14px',
      },
      overlay: {
        zIndex: 1200,
      },
      dialogContainer: {
        zIndex: 1200,
      },
      closeButton: {
        color: 'MagicWhite',
      },
      body: {
        fontSize: 'lg',
        fontWeight: 500,
      },
      header: {
        textTransform: 'capitalize',
        fontWeight: 800,
        fontSize: '32px',
      },
    },
  },
  defaultProps: {
    variant: 'McDefault',
  },
});
