import { useEffect, useRef } from 'react';
import getSantizedRoomIdFromUrl from '@common/getSantizedRoomIdFromRequest';
import { getDefaultStore } from 'jotai';
import { customAlphabet } from 'nanoid';
import { gameDetailsAtom, playerIdAtom } from '@/store/store';

const nanoid = customAlphabet('6789BCDFGHJKLMNPQRTW', 4);

export const getUrl = (): string => {
  return window.location.href;
};

export const getShareUrl = (): string => {
  const url = new URL(getUrl());

  const { get } = getDefaultStore();
  const playerId = get(playerIdAtom);
  const viewingGame = get(gameDetailsAtom);

  playerId && url.searchParams.set('inviterId', playerId);
  viewingGame && url.searchParams.set('game', viewingGame);

  return url.toString();
};

export const getUrlParts = (url: string): string[] => {
  return url.split('/');
};

export const getQueryParams = (): URLSearchParams => {
  const url = getUrl();
  return new URL(url).searchParams;
};

export const getQueryParamValue = (key: string): string | null => {
  return getQueryParams().get(key);
};

export const getRoomIdFromCurrentUrl = (): string | null => {
  return getSantizedRoomIdFromUrl(getUrl());
};

const navigateToRoom = (roomId: string | null): void => {
  const currentRoomId = getRoomIdFromCurrentUrl();
  if (roomId && roomId !== 'undefined' && currentRoomId !== roomId) {
    const urlParts = getUrlParts(getUrl());

    const rIndex = urlParts.indexOf('r');
    if (rIndex > -1) {
      urlParts[rIndex + 1] = roomId;
    } else {
      trimUrlParts(urlParts);
      urlParts.push('r', roomId);
    }

    const newURL = urlParts.join('/');
    window.location.href = newURL;
  }
};

export const redirectToRandomRoom = (): void => {
  const randomRoomId = nanoid();
  navigateToRoom(randomRoomId);
};

export function trimUrlParts(urlParts: string[]): string[] {
  while (urlParts.length > 0 && urlParts[urlParts.length - 1] === '') {
    urlParts.pop();
  }
  return urlParts;
}

/**
 *  Hook that calls a callback function every `delay` milliseconds.
 * @param callback  The callback function to call.
 * @param delay     The delay in milliseconds.
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

/**
 * Hook that calls a callback function after `delay` milliseconds.
 * @param callback The callback function to call.
 * @param delay The delay in milliseconds.
 */
export function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
}

export function downloadJsonAsFile(data: object) {
  // Create a blob from the JSON data
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json',
  });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = 'data.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
