import { CopyIcon } from '@chakra-ui/icons';
import { Button, Heading, useClipboard, useToast } from '@chakra-ui/react';
import StockDarkThemeChakraProvider from '@components/StockDarkThemeChakraProvider';
import styled from '@emotion/styled';
import { CheckpointManager } from './checkpoints';

interface ErrorFallbackProps {
  error: Error;
  componentStack: string;
  resetError: () => void;
}

const StackTraceLine = styled.div`
  margin-bottom: 2px;
`;

const ErrorContainer = styled.div`
  background-color: #191919;
  color: #721c24;
  color: white;
  margin: 15px;
  padding: 20px;
  border: 2px solid #ff8d8d;
  border-radius: 0.25rem;
  font-size: 1rem;
  user-select: text;

  @media (min-width: 576px) {
    font-size: 1.25rem;
  }
`;

const ErrorText = styled.div`
  font-weight: bold;
`;

const StackTrace = styled.pre`
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 0.75rem;
  overflow-y: scroll;
  max-height: 60vh;

  @media (min-width: 576px) {
    font-size: 0.85rem;
  }
`;

const DevTools = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

/**
 * ErrorFallback component.
 * This is a fallback UI that will be displayed when an error boundary catches an error.
 * It shows an error message, the component stack trace when the error happened, and a button to copy these details.
 *
 * @param {Object} props - The properties that define the Error and stack trace.
 * @param {Error} props.error - The error that was thrown.
 * @param {string} props.componentStack - The component stack trace from the point where the error was captured.
 * @returns {JSX.Element} The ErrorFallback component.
 *
 * @example
 * ```jsx
 * <ErrorBoundary
 *   fallback={({ error, componentStack }) => (
 *     <ErrorFallback error={error} componentStack={componentStack} />
 *   )}
 * >
 *   <App />
 * </ErrorBoundary>
 * ```
 */
const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  componentStack,
  resetError,
}) => {
  const errorData = `${error.toString()}${componentStack}`;
  const toast = useToast();
  const { onCopy } = useClipboard(errorData);

  /**
   * Formats the stack trace for display in the UI.
   * This function splits the stack trace string into individual lines and removes the protocol, hostname, and port from each line.
   * It then returns an array of JSX elements representing these lines.
   *
   * @param {string} stack - The stack trace string to be formatted.
   * @returns {JSX.Element[]} An array of JSX elements representing the formatted stack trace lines.
   */
  const formatStackTrace = (stack: string) => {
    const lines = stack.split('\n');
    return lines.map((line, i) => {
      const cleanedLine = line.replace(/http[s]?:\/\/[^/]*/, '');
      return <StackTraceLine key={i}>{cleanedLine}</StackTraceLine>;
    });
  };

  return (
    <ErrorContainer>
      <Heading size="md" color="#ff8d8d">
        😱 McRuntime error, yikes!
      </Heading>
      <StockDarkThemeChakraProvider>
        <DevTools>
          <Button
            leftIcon={<CopyIcon />}
            onClick={() => {
              onCopy();
              toast({
                position: 'top',
                title: 'Copied stacktrace to clipboard',
                status: 'info',
              });
            }}
          >
            Copy Stacktrace
          </Button>
          <CheckpointManager onCheckpointSelected={() => resetError()} />
        </DevTools>
      </StockDarkThemeChakraProvider>
      <ErrorText>{error.toString()}</ErrorText>
      <StackTrace>{formatStackTrace(componentStack)}</StackTrace>
    </ErrorContainer>
  );
};

export default ErrorFallback;
