import { useState } from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import McFlex from '@components/McFlex/McFlex';
import styled from '@emotion/styled';
import { DevCodeScanner } from './DevCodeScanner';
import { CheckpointManager } from './checkpoints';
import { useConfig } from '@/config';

interface ContainerProps {
  shouldMakeRoomForJotaiButton?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  left: ${({ shouldMakeRoomForJotaiButton }) =>
    shouldMakeRoomForJotaiButton ? 'calc(64px + 10px + 10px)' : '10px'};
  bottom: 10px;
  z-index: 9999;
`;

const ToggleButton = styled.button`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #dee2e6;
  font-size: 32px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: #ced4da;
  }
`;

export default function McDevTools() {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const [showCamera, setShowCamera] = useState(false);
  const configUrl = `https://config.magiccircle.dev/?url=${location.href}`;
  const isJotaiDevToolsButtonShowing = useConfig().root_JotaiDevTools;

  return (
    <>
      <Container shouldMakeRoomForJotaiButton={isJotaiDevToolsButtonShowing}>
        <ToggleButton onClick={onToggle}>
          <div
            style={{
              transform: isOpen ? 'rotate(-180deg)' : undefined,
              transition: '100ms ease-in-out',
            }}
          >
            🔮
          </div>
        </ToggleButton>
      </Container>
      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent
          containerProps={{ style: { zIndex: 99999 } }}
          style={{
            border: '3px solid transparent',
            borderImage:
              'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)',
            borderImageSlice: 1,
            borderRadius: 5,
          }}
        >
          <McFlex col gap="3" py="4">
            <McFlex gap="2">
              <CheckpointManager onCheckpointSelected={onClose} />
              {showCamera && (
                <Modal isOpen={true} onClose={() => setShowCamera(false)}>
                  <ModalOverlay />
                  <ModalContent containerProps={{ style: { zIndex: 999999 } }}>
                    <McFlex orient="left" height="10">
                      <CloseButton
                        pos="absolute"
                        onClick={() => setShowCamera(false)}
                        size="lg"
                      />
                      <McFlex>QR Scanner</McFlex>
                    </McFlex>
                    <DevCodeScanner />
                  </ModalContent>
                </Modal>
              )}
              <Button onClick={() => setShowCamera(true)}>QR</Button>
            </McFlex>
            <Button
              as={Link}
              href={configUrl}
              rightIcon={<ExternalLinkIcon />}
              target="_blank"
            >
              Open in McConfig
            </Button>
          </McFlex>
        </DrawerContent>
      </Drawer>
    </>
  );
}
