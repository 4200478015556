import React from 'react';
import Environment from '@common/Environment';
import Logger from '@common/Logger';
import McChakraProvider from '@components/McChakraProvider';
import Scope from '@components/Scope';
import { Logtail } from '@logtail/browser';
import * as Sentry from '@sentry/react';
import { detectIncognito } from 'detectincognitojs';
import { getDefaultStore } from 'jotai';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';
import App from './App';
import NoDesktopSupport from './NoDesktopSupport';
import ErrorFallback from './devtools/ErrorFallback';
import JotaiDevTools from './devtools/JotaiDevTools';
import MagicCircleDevTools from './devtools/MagicCircleDevTools';
import './index.css';
import { environment } from './environment';
import { currentGameNameAtom, playerAtom } from './store/store';
import { getRoomIdFromCurrentUrl, redirectToRandomRoom } from './utils';

// Clear console between HMR reloads
// Totally optional, feel free to remove
// https://github.com/vitejs/vite/discussions/3143
if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', () => console.clear());
}

const roomId = getRoomIdFromCurrentUrl();
// console.log(import.meta.env);

// Sentry is only intended to run in production
// Those build environment variables are are set in our GitHub Actions workflow
// and if they're not set, Sentry will silently be disabled
Sentry.init({
  dsn: 'https://30469ee95467485d96af791b5f669ab9@o4505315105243136.ingest.sentry.io/4505322901995520',
  release: import.meta.env.VITE_SENTRY_RELEASE,
  dist: 'client',
  enabled: environment === 'Production',
  environment: environment,
  // 100% sampling for now...
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  initialScope: {
    tags: {
      roomId,
    },
  },
  integrations: [
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

// Define Logtail tokens for different environments
const LOGTAIL_TOKENS: Partial<Record<Environment, string>> = {
  Production: 'awUaiM1Y5s28MpL9xs49BxMj',
  Preview: '8fXfkoicSnKiksDi6bzRCaK9',
};

const logtailToken = LOGTAIL_TOKENS[environment];
const logtailClient = logtailToken ? new Logtail(logtailToken) : undefined;
export const logger = new Logger(logtailClient);
if (logtailClient) {
  logger.monkeyPatchConsole();
  logger.setAttributes({
    source: 'client',
    roomId,
    environment,
  });
} else {
  console.warn(`Logtail disabled for '${environment}' environment`);
}

// Define Mixpanel tokens for different environments
const MIXPANEL_TOKENS: Partial<Record<Environment, string>> = {
  Production: 'fa5022257cae11c48f1e736ccff7b5f7',
  Preview: '03ed0dfee9a9e0b7f86619021c6ad949',
};

// Get the Mixpanel token based on the current environment
const mixpanelToken = MIXPANEL_TOKENS[environment];

mixpanel.init(mixpanelToken || 'unset', {
  debug: environment === 'Local',
  persistence: 'localStorage',
});

// Mixpanel has a dumb API and you have to create it before you disable it...
if (!mixpanelToken) {
  mixpanel.disable();
}

// Keep current player info updated in our analytics tools
const { sub, get } = getDefaultStore();
sub(playerAtom, () => {
  const player = get(playerAtom);
  // makes it to look for players like this: 🐶 Doggo
  const playerNameWithEmoji = `${player.cosmetic.emoji} ${player.name}`;

  // Mixpanel
  mixpanel.identify(player.id);

  // Sentry
  Sentry.setUser({
    id: player.id,
    username: playerNameWithEmoji,
  });

  // Logtail
  logger.setAttributes({
    playerId: player.id,
    playerName: playerNameWithEmoji,
  });
});

sub(currentGameNameAtom, () => {
  const gameName = get(currentGameNameAtom);

  // Sentry
  Sentry.setTag('gameName', gameName);

  // Logtail
  logger.setAttributes({
    gameName,
  });
});

if (!roomId) {
  redirectToRandomRoom();
}

detectIncognito()
  .then(({ isPrivate }) => {
    if (isPrivate) {
      console.log('Incognito mode detected');
    }
    Sentry.setTag('isIncognito', isPrivate);
  })
  .catch((err) => {
    console.warn('Error detecting incognito mode', err);
  });

// on any browser instances that loaded this PR before
// the theme was updated to use dark mode by default
localStorage.removeItem('chakra-ui-color-mode');

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Scope scope="Room">
      <McChakraProvider>
        {/* https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/ */}
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <ErrorFallback
              error={error}
              componentStack={componentStack}
              resetError={resetError}
            />
          )}
          showDialog
        >
          {isDesktop() ? <NoDesktopSupport /> : <App />}
        </Sentry.ErrorBoundary>
        <JotaiDevTools />
        <MagicCircleDevTools />
      </McChakraProvider>
    </Scope>
  </React.StrictMode>
);

// Hack to work around 100vh not working properly on Safari
// https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
const onWindowResize = () => {
  setTimeout(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    doc.style.setProperty('--system-header-height', '65px');
    window.scrollTo(0, 0);
  }, 150);
};

const setup = () => {
  window.addEventListener('resize', onWindowResize);
  onWindowResize();
};

function isDesktop() {
  if (environment !== 'Production') return false;

  const userAgent = window.navigator.userAgent;
  const platforms = [
    'Android',
    'iPhone',
    'iPod',
    'iPad',
    'Windows Phone',
    'webOS',
    'BlackBerry',
  ];

  for (let i = 0; i < platforms.length; i++) {
    if (userAgent.indexOf(platforms[i]) > -1) {
      return false;
    }
  }

  return true;
}

setup();
