import {
  InputHTMLAttributes,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Input, InputProps } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { createPortal } from 'react-dom';

function getDocumentFontDefinitions(): string {
  const fonts = [];
  for (const font of document.styleSheets[0].cssRules) {
    if (font instanceof CSSFontFaceRule) {
      fonts.push(font.cssText);
    }
  }
  return fonts.join('\n');
}

type Attribute = { name: string; value: string };

interface InnerIframeInputProps {
  attributes: Attribute[];
  passthroughProps: InputHTMLAttributes<HTMLInputElement>;
}

function InnerIframeInput({
  attributes,
  passthroughProps,
}: InnerIframeInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const input = inputRef.current;
    if (!input) return;
    attributes.forEach(({ name, value }) => {
      input.setAttribute(name, value);
    });
  }, [inputRef, attributes]);

  return <input ref={inputRef} {...passthroughProps} />;
}

function copyElementAppearance(input: HTMLInputElement) {
  const computedStyle = window.getComputedStyle(input);
  let styleString = '';
  for (let i = 0; i < computedStyle.length; i++) {
    // ignore visibility property and position property
    if (computedStyle[i] === 'visibility' || computedStyle[i] === 'position') {
      continue;
    }
    const key = computedStyle[i];
    let value = computedStyle.getPropertyValue(computedStyle[i]);
    if ((key === 'width' || key === 'max-width') && value.endsWith('%')) {
      value = `${input.offsetWidth}px`;
    }

    if ((key === 'height' || key === 'max-height') && value.endsWith('%')) {
      value = `${input.offsetHeight}px`;
    }

    styleString += `${key}:${value};`;
  }

  const attributesToSkip = ['style', 'class', 'id', 'value'];
  const attributes: Attribute[] = [...input.attributes];
  const attributesToCopy = attributes
    .filter((x) => !attributesToSkip.includes(x.name))
    .concat({ name: 'style', value: styleString });
  return {
    attributes: attributesToCopy,
    dimensions: {
      width: input.offsetWidth,
      height: input.offsetHeight,
    },
  };
}

// omit autoCapitalize because it causes a bug on iOS where you can't capitalize
// the first letter of the input
type IframeInputProps = Omit<InputProps, 'autoCapitalize'>;

/**
 * `IframeInput` is a React component that renders an input field within an
 * iframe. We do this to work around an unfortunate bug in iOS Safari where
 * input fields, even when fully removed from the DOM tree, will still trigger
 * the "Shake to Undo" prompt when the user shakes their device. This is true as
 * of iOS 16 and iOS 17, but hopefully will be fixed in a future version of iOS.
 *
 * The component is memoized and will only re-render if its props change. This
 * is a performance optimization, since the component is expensive to render, as
 * it involves rendering a "looks-like" input field in the DOM, copying its
 * appearance, and then rendering the actual input field in an iframe.
 *
 * @param {IframeInputProps} props - The properties for the input field.
 * @returns {ReactElement} A React element representing the input field in an iframe.
 */
const IframeInput = memo((props: IframeInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [iframeReactRoot, setIframeReactRoot] = useState<Element | null>(null);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const fontDefinitions = useRef(getDocumentFontDefinitions()).current;

  useLayoutEffect(() => {
    if (!inputRef.current) return;
    const { attributes, dimensions } = copyElementAppearance(inputRef.current);
    setAttributes(attributes);
    setDimensions(dimensions);
  }, [props]);

  return (
    <>
      <iframe
        style={{ border: 'none' }}
        width={dimensions.width}
        height={dimensions.height}
        onLoad={({ target }) => {
          // React isn't happy about being mounted directly into the <body> or
          // <html>, so we create a <div> and mount into that instead.
          const iframe = target as HTMLIFrameElement;
          const root = iframe.contentDocument?.body?.firstElementChild;

          if (!root) {
            throw new Error('Could not find root element in iframe');
          }

          setIframeReactRoot(root);
        }}
        srcDoc={`<!DOCTYPE html>
                  <html>
                  	<style>
                      ${fontDefinitions}
                      ::placeholder {
                        opacity: 0.24;
                      }
                    </style>
                    <body style="margin:0; padding:0; box-sizing: border-box; background-color: ${document.body.style.backgroundColor};">
                      <div></div>
                    </body>
                  </html>`}
      />
      {/* This is our invisible "looks-like" input that we use to get the computed styles */}
      {createPortal(
        <Input
          ref={inputRef}
          {...props}
          style={{ visibility: 'hidden', position: 'absolute' }}
        />,
        document.body
      )}
      {/* This is the user-interactable input that we render in the iframe */}
      {iframeReactRoot &&
        createPortal(
          <InnerIframeInput
            attributes={attributes}
            passthroughProps={{
              value: props.value,
              defaultValue: props.defaultValue,
              onChange: props.onChange,
              onBlur: props.onBlur,
              onFocus: props.onFocus,
              onKeyDown: props.onKeyDown,
              onKeyUp: props.onKeyUp,
              onInput: props.onInput,
              onInvalid: props.onInvalid,
            }}
          />,
          iframeReactRoot
        )}
    </>
  );
}, isEqual);

export default IframeInput;
