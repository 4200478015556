import { DevTools } from 'jotai-devtools';
import { useConfig } from '@/config';
import { environment } from '@/environment';

const JotaiDevTools = () => {
  // Regardless of what the config says, we can never show in a real build
  // environment because it gets tree-shaken out during `vite build`
  const showTools = useConfig().root_JotaiDevTools && environment === 'Local';

  if (!showTools) {
    return null;
  }
  return <DevTools theme="dark" />;
};

export default JotaiDevTools;
