type ConfigValueSchema =
  | string
  | number
  | boolean
  | ReadonlyArray<ConfigValueSchema>
  | { [key: string]: ConfigValueSchema };

type ConfigKeySchema = `${string}_${string}`;
type ConfigSchema = Readonly<Record<ConfigKeySchema, ConfigValueSchema>>;

export const defaults = {
  root_SendSlackWebHooksEvenIfNotProduction: false,
  root_JotaiDevTools: false,
  root_MagicCircleDevTools: false,
  root_StartTimerSeconds: 5,
  root_secondsAfterLastSeenToKickPlayerInLobby: 45,
  root_bypassMinPlayersCheck: false,
  root_autoReadyUp: false,
  root_hiddenGames: ['TellTaleHeart'],

  avocado_isPromptingTimed: true,
  avocado_promptingTimerSeconds: 60,
  avocado_isChoosingTimed: true,
  avocado_choosingTimerSeconds: 20,
  avocado_isBettingTimed: true,
  avocado_bettingTimerSeconds: 30,
  avocado_isRevealTimed: true,
  avocado_revealLabelTimerSeconds: 0.5,
  avocado_revealSlapTimerSeconds: 1.2,
  avocado_revealQuickSlaps: false,

  mcmind_lives: 3,
  mcmind_isPressAndHoldEnabled: true,

  noseolympics_shapeTraceShape: 'circle',
  noseolympics_showDebugTools: false,
  noseolympics_AllowedDistance: 20,

  trebek_useMockQuizItems: false,
  trebek_useMockTranscript: false,
  trebek_cardDwellSeconds: 5,
  trebek_isShakeEnabled: true,
  trebek_millisBeforeSkipButtonAppears: 5000,
  trebek_scoreScreenSeconds: 5,
  trebek_secondsBeforeFinishButtonAppears: 5,
  trebek_upNextScreenSeconds: 3,
  trebek_votingSeconds: 40,
} as const satisfies ConfigSchema;

/**
 * This config type will always reflect DEFAULT config values.
 * Runtime values MAY differ, if they have been overriden due to:
 * 1) A /config POST request, which overrides the config for a specific room
 * 2) A local-overrides.json file, which overrides the config for all rooms (when running locally)
 */
export type Config = typeof defaults;
