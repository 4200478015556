import colors from 'src/theme/colors';
import { GameMetaData } from '../types';
// import NoseOlympicsLogo from './assets/NoseOlympicsLogo.svg';
// import NoseOlympicsCoverArt from './assets/nose-olympics-cover.jpg';
import NoseOlympicsCoverArt from './assets/cupcake.png';

const NoseOlympicsMetaData: GameMetaData = {
  name: 'Nose Olympics',
  cardColor: colors.MagicBlue,
  cardImage: NoseOlympicsCoverArt,
  coverArt: NoseOlympicsCoverArt,
  tagline:
    'A series of competitions in which players participate using their noses.',
  howToPlay: 'Coming soon...',
  youWillNeed: [
    {
      emoji: '👃',
      text: 'A nose',
    },
  ],
  minPlayers: 2,
};

export default NoseOlympicsMetaData;
