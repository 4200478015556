import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import WebSocketCloseCode from '@common/WebSocketCloseCode';
import McFlex, { McFlexProps } from '@components/McFlex/McFlex';
import PlayerAvatar from '@components/PlayerAvatar/PlayerAvatar';
import { useRoomConnection } from '@/connection/hooks';
import { useSendRoomMessage } from '@/hooks';
import { usePlayerId, usePlayersWithSecondsSinceLastSeen } from '@/store/store';
import { redirectToRandomRoom } from '@/utils';

function KickButton({ targetPlayerId }: { targetPlayerId: string }) {
  const sendRoomMessage = useSendRoomMessage();
  return (
    <Button
      marginLeft="auto"
      leftIcon={<DeleteIcon />}
      onClick={() => {
        sendRoomMessage({ type: 'KickPlayer', targetPlayerId });
      }}
      size="sm"
      bg="MagicRed"
    >
      <Text variant="Bogart" color="white" pt="2px" size="xs">
        Kick
      </Text>
    </Button>
  );
}

function LeaveButton() {
  const roomConnection = useRoomConnection();
  return (
    <Button
      marginLeft="auto"
      onClick={() => {
        roomConnection.disconnect(WebSocketCloseCode.PlayerLeftVoluntarily);
        redirectToRandomRoom();
      }}
      size="sm"
      bg="MagicPurple"
    >
      <Text variant="Bogart" color="white" pt="2px" size="xs">
        Leave
      </Text>
    </Button>
  );
}

interface PlayerDrawerProps extends McFlexProps {
  internalScrollbar?: boolean;
}

const SecondsSinceLastSeenToConsiderInactive = 10;
const PlayersDrawer: React.FC<PlayerDrawerProps> = ({
  internalScrollbar = true,
  ...rest
}) => {
  const players = usePlayersWithSecondsSinceLastSeen().map((player) => ({
    ...player,
    isIdle:
      player.secondsSinceLastSeen > SecondsSinceLastSeenToConsiderInactive,
  }));
  const myPlayerId = usePlayerId();

  return (
    <McFlex
      col
      orient="top"
      pt={12}
      overflow={internalScrollbar ? 'auto' : undefined}
      {...rest}
    >
      <Text variant="Bogart" size="lg" color="MagicWhite">
        Who's Here
      </Text>
      <Flex
        flexDir="column"
        w="100%"
        overflow={internalScrollbar ? 'auto' : undefined}
        mt={4}
        pl={internalScrollbar ? 4 : undefined}
        gap={2}
      >
        {players.map((player) => (
          <Flex
            key={player.id}
            align="center"
            justify="left"
            w="100%"
            px={internalScrollbar ? 4 : undefined}
            gap={2}
          >
            <Box pos="relative">
              <PlayerAvatar
                size="sm"
                player={player}
                col={false}
                opacity={player.isIdle ? 0.5 : 1}
              />
              {!player.isIdle && (
                <Flex
                  pos="absolute"
                  bottom="0px"
                  left="35px"
                  w="15px"
                  h="15px"
                  borderRadius="50%"
                  border="2px solid"
                  borderColor="MagicBlack"
                  bg="LightGreen"
                />
              )}
            </Box>
            {myPlayerId === player.id && (
              <>
                <Text color="MagicWhite">(you)</Text>
                <LeaveButton />
              </>
            )}
            {myPlayerId !== player.id && (
              <KickButton targetPlayerId={player.id} />
            )}
          </Flex>
        ))}
      </Flex>
    </McFlex>
  );
};

export default PlayersDrawer;
