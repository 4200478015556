import { GameMetaData } from '../types';
import AvocadoCoverArt from './assets/opinionparty.png';

const AvocadoMetaData: GameMetaData = {
  name: 'Avocado',
  cardColor: '#b92b56',
  cardImage: AvocadoCoverArt,
  tagline: 'Silly • Icebreaker',
  coverArt: AvocadoCoverArt,
  howToPlay: 'Coming soon...',
  youWillNeed: [
    {
      emoji: '🧠',
      text: 'A heart',
    },
  ],
  minPlayers: 2,
};

export default AvocadoMetaData;
