import { ThemeOverride, defineStyleConfig } from '@chakra-ui/react';
const TrebekTheme: ThemeOverride = {
  components: {
    Button: defineStyleConfig({
      // The styles all button have in common
      baseStyle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        borderRadius: '6px', // <-- border radius is same for all variants and sizes
      },
      // Two variants: outline and solid
      variants: {
        outline: {
          border: '2px solid',
          borderColor: 'MagicWhite',
          color: 'MagicWhite',
          fontFamily: 'GreyCliff CF',
        },
        solid: {
          bg: 'MagicWhite',
          color: 'MagicBlack',
          fontFamily: 'GreyCliff CF',
          _hover: {
            bg: '<color>',
            opacity: 0.9,
          },
          _active: {
            bg: '<color>',
            opacity: 0.8,
          },
          _disabled: {
            // color becomes transparent when disabled and clicked without
            // !important
            opacity: '0.4 !important',
          },
        },
        primary: {
          bg: 'MagicPurple',
          color: 'MagicWhite',
          fontFamily: 'GreyCliff CF',
          _hover: {
            bg: 'MagicPurple',
            color: 'MagicWhite',
          },
          _disabled: {
            // color becomes transparent when disabled and clicked without !important
            bg: 'MagicPurple !important',
            opacity: '0.4 !important',
          },
          _active: {
            opacity: 0.7,
          },
        },
        positive: {
          bg: 'BabyBlue',
          fontSize: 'lg',
          fontFamily: 'GreyCliff CF',
          _active: {
            opacity: 0.7,
          },
          _disabled: {
            pointerEvents: 'none',
          },
        },
        negative: {
          bg: 'MagicRed',
          fontFamily: 'GreyCliff CF',
          fontSize: 'lg',
          _active: {
            opacity: 0.7,
          },
          _disabled: {
            pointerEvents: 'none',
          },
        },
      },
      // The default size and variant values
      defaultProps: {
        size: 'md',
        variant: 'solid',
      },
    }),
    Heading: defineStyleConfig({
      variants: {
        normal: {
          color: 'MagicWhite',
          fontFamily: 'GreyCliff CF',
        },
      },
      defaultProps: {
        variant: 'normal',
      },
    }),
    Text: defineStyleConfig({
      variants: {
        normal: {
          color: 'MagicWhite',
          fontFamily: 'GreyCliff CF',
        },
      },
      defaultProps: {
        variant: 'normal',
      },
    }),
  },
};

export default TrebekTheme;
