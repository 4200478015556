import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import {
  Button,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import IframeInput from '@components/IframeInput';
import McFlex from '@components/McFlex/McFlex';
import ShareWidget from '@components/SystemHeader/components/ShareWidget';

const QrDrawer = () => {
  const [roomIdInputValue, setRoomIdInputValue] = useState('');

  // tabPanelsHeight must be calculated to fill the remaining space of the <Tabs> container
  const [tabPanelsHeight, setTabPanelsHeight] = useState<string>('100%');
  const tabListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const height = tabListRef.current?.clientHeight || 0;
    setTabPanelsHeight(`calc(100% - ${height + 2}px)`);
  }, [tabListRef]);

  const onChangeRoomIdField = (e: ChangeEvent<HTMLInputElement>) => {
    const cleanedRoomId = e.target.value
      .replace(/\s/g, '') // Remove all whitespace
      .toUpperCase(); // Uppercase
    setRoomIdInputValue(cleanedRoomId);
  };

  const onKeyDownRoomIdField = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClickJoinRoom();
    }
  };

  const fetchRoomInfo = async (roomId: string) => {
    const response = await fetch(`/api/rooms/${roomId}/info`);
    const data = await response.json();
    return data;
  };

  const onClickJoinRoom = async () => {
    if (roomIdInputValue.length === 0) return;
    const pathname = `/r/${roomIdInputValue}`;
    console.log(`Navigating to: ${pathname}`);
    // just as a fail-safe, we wrap this in a try/catch
    // and proceed blindly to the new room if we can't get info
    try {
      const roomInfo = await fetchRoomInfo(roomIdInputValue);
      if (roomInfo.numPlayers === 0) {
        const confirmation = window.confirm(
          "There's no one in that room, are you sure you want to join it?\n\nMaybe a typo 🤔"
        );
        if (!confirmation) return;
      }
    } catch (e) {
      console.error(e);
    }
    location.pathname = pathname;
  };

  return (
    <McFlex id="TabsContainer" col orient="top" alignItems="inherit" mt={10}>
      <Tabs flexGrow={1} variant="unstyled">
        <TabList ref={tabListRef} justifyContent="space-evenly">
          <Tab>
            <Text color="MagicWhite">INVITE PLAYERS</Text>
          </Tab>
          <Tab>
            <Text color="MagicWhite">JOIN A ROOM</Text>
          </Tab>
        </TabList>
        <TabIndicator />
        <TabPanels h={tabPanelsHeight}>
          <TabPanel h="100%">
            <ShareWidget />
          </TabPanel>
          <TabPanel h="100%">
            <McFlex col px="10%">
              <McFlex col orient="top" mt="30px" gap="20px">
                <Heading size="lg" color="MagicWhite">
                  Room Code
                </Heading>
                <IframeInput
                  placeholder="????"
                  bg="MagicBlack"
                  value={roomIdInputValue}
                  onChange={onChangeRoomIdField}
                  onKeyDown={onKeyDownRoomIdField}
                  fontSize="30px"
                  letterSpacing="10px"
                  maxLength={8}
                />
                <Button variant="white" onClick={onClickJoinRoom}>
                  JOIN ROOM
                </Button>
                <Text size="sm" color="MagicWhite" textAlign="center">
                  You'll leave this room behind <br />
                  if you join a new one.
                </Text>
                <Text fontSize={250}>🚪</Text>
              </McFlex>
            </McFlex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </McFlex>
  );
};

export default QrDrawer;
