import { useEffect, useRef, useState } from 'react';
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import drawerContent, { drawerType } from './Content';
import { useCloseDrawer, useDrawerType } from '@/store/store';

const SystemDrawer = () => {
  const drawerType = useDrawerType();
  const closeDrawer = useCloseDrawer();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const currentType = useRef<drawerType | null>(null);

  // If a player needs to go from one drawer to another
  // We close the drawer for 300ms, then open the new one
  useEffect(() => {
    if (drawerType) {
      if (isOpen) {
        setIsOpen(false);
        setTimeout(() => {
          currentType.current = drawerType;
          setIsOpen(true);
        }, 300);
      } else {
        currentType.current = drawerType;
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerType]);

  const showDrawerCloseButton =
    currentType.current !== 'profile-afterAcceptingInvite';

  const SystemDrawerContent = currentType.current
    ? drawerContent[currentType.current]
    : () => <div />;

  return (
    <Drawer isOpen={isOpen} onClose={closeDrawer} placement="bottom">
      <DrawerOverlay />
      <DrawerContent
        // h="calc(var(--app-height) - var(--system-header-height))"
        h="calc(var(--app-height))"
        bg="MagicBlack"
      >
        {showDrawerCloseButton && <DrawerCloseButton top={3} left={3} />}
        <SystemDrawerContent />
      </DrawerContent>
    </Drawer>
  );
};

export default SystemDrawer;
