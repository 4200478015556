const emojis = [
  '😈', // Devil
  '👻', // Ghost
  '🐺', // Wolf
  '🐥', // Chick
  '🐬', // Dolphin
  '👽', // Alien
  '🤖', // Robot
  '🎃', // Pumpkin
  '🦄', // Unicorn
  '👹', // Japanese Ogre (resembling a devil mask)
  '🐶', // Dog
  '🐱', // Cat
  '🐭', // Mouse
  '🐰', // Rabbit
  '🐻', // Bear
  '🐯', // Tiger
  '🦁', // Lion
  '🐮', // Cow
  '🐷', // Pig
  '🐸', // Frog
  '🐵', // Monkey
  '🦝', // Raccoon
  '🐗', // Boar
  '🐴', // Horse
  '🦓', // Zebra
  '🦍', // Gorilla
  '🐼', // Panda
  '🐻‍❄️', // Polar bear
  '🐨', // Koala
  '🐙', // Octopus
  '🐉', // Dragon
  '🦏', // Rhino

  // '😈', // Devil
  // '👻', // Ghost
  // '🐺', // Wolf
  // '🐥', // Chick
  // '🐬', // Dolphin
  // '👽', // Alien
  // '🤖', // Robot
  // '🎃', // Pumpkin
  // '🦄', // Unicorn
  // '👹', // Japanese Ogre (resembling a devil mask)
  // '🐶', // Dog
  // '🐱', // Cat
  // '🐭', // Mouse
  // '🐰', // Rabbit
  // '🐻', // Bear
  // '🐯', // Tiger
  // '🦁', // Lion
  // '🐮', // Cow
  // '🐷', // Pig
  // '🐸', // Frog
  // '🐵', // Monkey
  // '🦝', // Raccoon
  // '🐗', // Boar
  // '🐴', // Horse
  // '🦓', // Zebra
  // '🦍', // Gorilla
  // '🐼', // Panda
  // '🐻‍❄️', // Polar bear
  // '🐨', // Koala
  // '🐙', // Octopus
  // '🐉', // Dragon
  // '🦏', // Rhino
] as const;

export type AllowedEmoji = (typeof emojis)[number];

export function isAllowedEmoji(emoji: string): emoji is AllowedEmoji {
  return emojis.includes(emoji as unknown as AllowedEmoji);
}

export default emojis;
