/**
 * Determines the current platform.
 * If the global object has a 'window' property, the environment is considered to be a 'browser'.
 * Otherwise, the platform is considered to be 'other'.
 *
 * @returns {'browser' | 'other'} The current environment ('browser' or 'other')
 */
export function getPlatform(): 'browser' | 'other' {
  if ('window' in globalThis) {
    return 'browser';
  } else {
    return 'other';
  }
}
