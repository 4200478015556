import { ThemeOverride } from '@chakra-ui/react';
import colors from 'src/theme/colors';

const LobbyTheme: ThemeOverride = {
  components: {
    Text: {
      baseStyle: {
        color: colors.White,
        textAlign: 'center',
      },
    },
  },
};

export default LobbyTheme;
