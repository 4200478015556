import { defineStyleConfig } from '@chakra-ui/react';
import colors from '../colors';

const ButtonTheme = defineStyleConfig({
  baseStyle: {
    color: 'white',
    borderRadius: 6,
    textTransform: 'uppercase',
    _hover: {
      opacity: 0.7,
      // fix for incredibly stupid chakra default theme
      // https://github.com/chakra-ui/chakra-ui/blob/eb0316ddf96dd259433724062e923c33e6eee729/packages/components/theme/src/components/button.ts#L20
      _disabled: null,
    },
    _active: {
      opacity: 0.7,
    },
    _disabled: {
      opacity: 0.7,
    },
    fontFamily: 'Greycliff CF',
    fontWeight: 700,
  },
  sizes: {
    xs: {
      fontSize: '12px',
    },
    sm: {
      fontSize: '16px',
      px: 4,
      py: 4,
    },
    md: {
      fontSize: '24px',
      px: 6,
      py: 6,
    },
    lg: {
      fontSize: '32px',
      px: 8,
      py: 6,
    },
  },
  variants: {
    outline: {
      bg: 'transparent',
      border: '3px solid',
      borderColor: 'MagicWhite',
      color: 'MagicWhite',
    },
    outlineInverse: {
      bg: 'transparent',
      border: '3px solid',
      borderColor: 'MagicBlack',
      color: 'MagicBlack',
    },
    black: {
      bg: 'MagicBlack',
    },
    white: {
      bg: 'MagicWhite',
      color: 'MagicBlack',
    },
    red: {
      bg: colors.MagicRed,
      fontFamily: 'Baloo',
    },
    PermissionModalAllow: {
      bg: 'BabyBlue',
      fontSize: 'lg',
      width: '100%',
    },
    PermissionModalDeny: {
      bg: 'MagicGray',
      fontSize: 'lg',
      width: '100%',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'black',
  },
});

export default ButtonTheme;
