import Player, { PlayerId } from '@common/types/player';
import Emoji from '@components/Emoji/Emoji';
import McCircle, { McCircleProps } from '@components/McCircle/McCircle';
import colors from 'src/theme/colors';
import { usePlayerById } from '@/store/store';

interface PlayerTokenProps extends McCircleProps {
  playerId?: PlayerId;
  player?: Player;
  hasTokenShadow?: boolean;
  hasBorder?: boolean;
}

const PlayerToken: React.FC<PlayerTokenProps> = ({
  playerId,
  player,
  size,
  hasTokenShadow = true,
  hasBorder = true,
  ...rest
}) => {
  const _playerId = playerId ?? player?.id ?? '';
  const _player = usePlayerById(_playerId);
  const emoji = _player?.cosmetic.emoji || '👤';
  const color = _player?.cosmetic.color || colors.MagicLightGray;

  return (
    <McCircle
      size={size}
      bg={color}
      boxShadow={
        hasTokenShadow ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' : undefined
      }
      hasBorder={hasBorder}
      {...rest}
    >
      <Emoji emoji={emoji} size={size} {...rest} />
    </McCircle>
  );
};

export default PlayerToken;
