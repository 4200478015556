import { useState } from 'react';
import {
  Button,
  Circle,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import ColorPicker from '@components/ColorPicker/ColorPicker';
import McFlex from '@components/McFlex/McFlex';
import { PanInfo, motion } from 'framer-motion';
import pluralize from 'pluralize';
import ProfileEmojiPicker from './ProfileEmojiPicker';
import ProfileNameInput from './ProfileNameInput';
import ProfilePlayerToken from './ProfilePlayerToken';
import { useSendRoomMessage } from '@/hooks';
import {
  useAcknowledgeAvatar,
  useCloseDrawer,
  useCosmetic,
  useDrawerType,
  useNumPlayersWithoutLeavers,
  useOpenDrawer,
  useSetCosmetic,
} from '@/store/store';

const ProfileDrawer = () => {
  const sendMessage = useSendRoomMessage();
  const cosmetic = useCosmetic();
  const setCosmetic = useSetCosmetic();
  const numOtherPlayers = useNumPlayersWithoutLeavers() - 1;
  const openDrawer = useOpenDrawer();
  const closeDrawer = useCloseDrawer();
  const drawerType = useDrawerType();
  const acknowledgeAvatar = useAcknowledgeAvatar();

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = (index: number) => {
    setTabIndex(index);
  };

  const onDragEnd = (_: unknown, info: PanInfo) => {
    if (tabIndex === 0 && info.offset.x < -100) {
      onChangeTab(1);
    } else if (tabIndex === 1 && info.offset.x > 100) {
      onChangeTab(0);
    }
  };

  const onClickColor = (color: string) => {
    setCosmetic({ ...cosmetic, color });
    sendMessage({ type: 'SelectColor', color });
  };

  let greeting = '';
  if (drawerType === 'profile-beforeInviting') {
    greeting = 'First, this you?';
  }
  if (drawerType === 'profile-afterAcceptingInvite') {
    greeting = "You're invited!";
  }

  return (
    <McFlex col orient="top" pt={10} gap={4} overflowY="auto">
      {greeting && (
        <Text
          as="h1"
          size="lg"
          variant="Bogart"
          color="White"
          textAlign="center"
          lineHeight={1}
          w="70%"
        >
          {greeting}
        </Text>
      )}

      {drawerType === 'profile-afterAcceptingInvite' && (
        <Flex alignItems="center" gap={2} mt={-3}>
          <Circle size="12px" bg="MagicGreen" mt={-1} />
          <Text variant="Bogart" size="sm" color="MagicWhite">
            {numOtherPlayers} {pluralize('players', numOtherPlayers)} here
          </Text>
        </Flex>
      )}

      <ProfilePlayerToken />

      <ProfileNameInput />

      <Button
        variant="white"
        w="252px"
        onClick={() => {
          acknowledgeAvatar();
          drawerType === 'profile' && closeDrawer();
          drawerType === 'profile-beforeInviting' && openDrawer('qr');
          drawerType === 'profile-afterAcceptingInvite' && closeDrawer();
        }}
      >
        {drawerType === 'profile' && "That's Me!"}
        {drawerType === 'profile-beforeInviting' && 'Continue'}
        {drawerType === 'profile-afterAcceptingInvite' && 'Join Game'}
      </Button>

      <Tabs
        h="100%"
        align="center"
        isFitted
        index={tabIndex}
        onChange={onChangeTab}
        overflowY="hidden"
        variant="unstyled"
      >
        <TabList>
          <Tab>
            <Text color="MagicWhite">ICON</Text>
          </Tab>
          <Tab>
            <Text color="MagicWhite">COLOR</Text>
          </Tab>
        </TabList>
        <TabIndicator />
        <TabPanels h="100%" overflowY="auto">
          <TabPanel pb="60px">
            <motion.div
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.5}
              onDragEnd={onDragEnd}
            >
              <ProfileEmojiPicker />
            </motion.div>
          </TabPanel>
          <TabPanel pb="60px">
            <motion.div
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.5}
              onDragEnd={onDragEnd}
            >
              <ColorPicker
                onClick={onClickColor}
                selectedColor={cosmetic.color}
              />
            </motion.div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </McFlex>
  );
};

export default ProfileDrawer;
