import getSantizedRoomIdFromUrl from '@common/getSantizedRoomIdFromRequest';
import { Result, useZxing } from 'react-zxing';

function onDecodeResult(result: Result) {
  if (!result) {
    return;
  }
  const text = result.getText();
  if (!text.startsWith('http')) {
    console.error(`QR code does not contain a URL: ${text}`);
    return;
  }
  const roomId = getSantizedRoomIdFromUrl(text);
  if (!roomId) {
    console.error(`Failed to parse roomId from URL: ${text}`);
    return;
  }
  // redirect to roomId, preserving the current hostname and protocol
  const pathname = `/r/${roomId}`;
  console.log(`Navigating to: ${pathname}`);
  location.pathname = pathname;
}
export function DevCodeScanner() {
  const { ref } = useZxing({
    constraints: {
      video: {
        aspectRatio: 1,
        facingMode: 'environment',
      },
    },
    // onError: (error) => {
    //   console.error(error);
    // },
    onDecodeResult,
  });

  return (
    <video
      ref={ref}
      style={{
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
      }}
    />
  );
}
