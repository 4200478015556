import { Box, FlexProps, useStyleConfig } from '@chakra-ui/react';

export interface EmojiProps extends FlexProps {
  emoji: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Emoji: React.FC<EmojiProps> = ({ emoji, size, ...rest }) => {
  const styles = useStyleConfig('Emoji', { size });

  return (
    <Box
      __css={styles}
      {...rest}
      textShadow={size === 'xs' ? 'none' : undefined}
    >
      {emoji}
    </Box>
  );
};

export default Emoji;
