import { ThemeOverride } from '@chakra-ui/react';

const NoseOlympicsTheme: ThemeOverride = {
  components: {
    Button: {
      // The styles all button have in common
      baseStyle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        borderRadius: 'base', // <-- border radius is same for all variants and sizes
      },
      // Two variants: outline and solid
      variants: {
        outline: {
          border: '2px solid',
          borderColor: 'purple.500',
          color: 'purple.500',
        },
        solid: {
          bg: 'purple.500',
          color: 'white',
        },
      },
      // The default size and variant values
      defaultProps: {
        size: 'md',
        variant: 'solid',
      },
    },
  },
};

export default NoseOlympicsTheme;
