import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    color: 'MagicWhite',
    fontFamily: 'GreyCliff CF',
    fontWeight: 800,
  },
  variants: {
    McDefault: {},
  },
  defaultProps: {
    variant: 'McDefault',
  },
});
