import { ThemeOverride } from '@chakra-ui/react';
import LobbyTheme from 'src/games/Lobby/LobbyTheme';
import McMindTheme from 'src/games/McMind/McMindTheme';
import NoseOlympicsTheme from 'src/games/NoseOlympics/NoseOlympicsTheme';
import SolidStareTheme from 'src/games/SolidStare/SolidStareTheme';
import TellTaleHeartTheme from 'src/games/TellTaleHeart/TellTaleHeartTheme';
import TrebekTheme from 'src/games/Trebek/TrebekTheme';
import YunomiTheme from 'src/games/Yunomi/YunomiTheme';
import RoomTheme from './RoomTheme';
import AvocadoTheme from '@/games/Avocado/AvocadoTheme';

const themes: Record<string, ThemeOverride> = {
  'Room.Avocado': AvocadoTheme,
  'Room.McMind': McMindTheme,
  'Room.SolidStare': SolidStareTheme,
  'Room.Yunomi': YunomiTheme,
  'Room.Trebek': TrebekTheme,
  'Room.NoseOlympics': NoseOlympicsTheme,
  'Room.TellTaleHeart': TellTaleHeartTheme,
  'Room.Lobby': LobbyTheme,
  Room: RoomTheme,
};

export default themes;
