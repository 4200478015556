import { Text } from '@chakra-ui/react';
import Player from '@common/types/player';
import { McCircleProps } from '@components/McCircle/McCircle';
import McFlex from '@components/McFlex/McFlex';
import PlayerToken from '@components/PlayerToken/PlayerToken';
import { useAtomValue } from 'jotai';
import { playerIdAtom } from '@/store/store';

interface PlayerTokenProps extends McCircleProps {
  player: Player;
  showYou?: boolean;
  col?: boolean;
  showName?: boolean;
}

const PlayerAvatar: React.FC<PlayerTokenProps> = ({
  player,
  showYou = false,
  showName = true,
  col = true,
  size,
  ...rest
}) => {
  const myId = useAtomValue(playerIdAtom);

  return (
    <McFlex
      col={col}
      width={col ? undefined : 'auto'}
      pos="relative"
      gap={col ? undefined : 3}
    >
      <PlayerToken player={player} size={size} {...rest} />
      {showName && (
        <Text size={size} color="White">
          {player.name}
        </Text>
      )}
      {showYou && myId === player.id && (
        <Text size={size} color="White">
          (you)
        </Text>
      )}
    </McFlex>
  );
};

export default PlayerAvatar;
