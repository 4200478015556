import React from 'react';
import { CopyIcon } from '@chakra-ui/icons';
import { Flex, GridProps, Text } from '@chakra-ui/layout';
import { IconButton, useClipboard, useToast } from '@chakra-ui/react';
import McFlex from '@components/McFlex/McFlex';
import QRCode from '@components/QRCode';
import ShareButton from '@components/ShareButton';
import McGrid from '@components/ui/McGrid';
import { getRoomIdFromCurrentUrl, getShareUrl } from '@/utils';

interface ShareWidgetProps extends GridProps {
  compact?: boolean;
}

const ShareWidget: React.FC<ShareWidgetProps> = ({
  compact = false,
  ...rest
}) => {
  const roomId = getRoomIdFromCurrentUrl();
  const toast = useToast();
  const url = getShareUrl();
  const { onCopy } = useClipboard(url);
  return (
    <McGrid
      r={compact ? '20% 62% 20%' : '23% 51% 26%'}
      borderRadius={12}
      bg="White"
      {...rest}
    >
      <McFlex col>
        <Text size={compact ? 'sm' : 'md'} fontWeight={800}>
          Room Code
        </Text>
        <Text
          size={compact ? 'md' : '2xl'}
          fontWeight={800}
          letterSpacing={4}
          mt={-2}
        >
          {roomId}
        </Text>
      </McFlex>
      <Flex justifyContent="center" alignItems="center">
        <QRCode value={url} size={compact ? '105%' : '95%'} />
      </Flex>
      <McFlex gap="2">
        <ShareButton
          borderRadius={12}
          px={6}
          shareData={{
            title: 'Join my Magic Circle!',
            text: 'Join my Magic Circle!',
            url,
          }}
          size={compact ? 'sm' : 'md'}
        />
        {!compact && (
          <IconButton
            aria-label="copy link"
            onClick={() => {
              onCopy();
              toast({
                title: 'Link copied to clipboard',
                status: 'success',
              });
            }}
            borderRadius={12}
            size="lg"
            icon={<CopyIcon />}
          />
        )}
      </McFlex>
    </McGrid>
  );
};

export default ShareWidget;
