import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';

export interface McCircleProps extends BoxProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  hasBorder?: boolean;
}

const McCircle: React.FC<McCircleProps> = ({
  children,
  size,
  hasBorder = true,
  ...rest
}) => {
  const styles = useStyleConfig('McCircle', { size });

  return (
    <Box __css={styles} {...rest} border={hasBorder ? undefined : 'none'}>
      {children}
    </Box>
  );
};

export default McCircle;
