const colors = {
  BrightRed: '#ED1B24',
  MagicRed: '#D02128',
  DarkRed: '#BF1E2D',
  LightOrange: '#FCB040',
  MagicOrange: '#F1592A',
  LightSalmon: '#F8986E',
  MagicYellow: '#FEF200',
  Gold: '#F7D540',
  Chartreuse: '#E9E85A',
  Pear: '#D8DF20',
  LimeGreen: '#8CC63E',
  LightGreen: '#68BD44',
  MagicGreen: '#5EAC46',
  DarkGreen: '#149248',
  CloverGreen: '#3DB971',
  MintGreen: '#60C29C',
  LightTeal: '#53C2BB',
  Teal: '#3CBEBC',
  DeepTeal: '#12A89D',
  DarkCyan: '#01907E',
  SeaFoamBlue: '#2EBAD1',
  BabyBlue: '#25AAE2',
  LightBlue: '#0F75BD',
  MagicBlue: '#0067B4',
  DarkBlue: '#213E9A',
  LightPurple: '#9B59B6',
  MagicPurple: '#7F3E98',
  DeepPurple: '#662E91',
  RoyalPurple: '#9C298E',
  RosePink: '#D91A5D',
  Magenta: '#DE1F87',
  HotPink: '#ED1790',
  DarkSalmon: '#DF4257',
  Pink: '#F2718F',
  LightPink: '#F191AC',
};

export default colors;
