import React from 'react';
import { Button, ButtonProps, Image } from '@chakra-ui/react';
import ShareIcon from '@/assets/svgs/share-icon.svg';

type ShareData = {
  title: string;
  text: string;
  url: string;
};

interface ShareButtonProps extends ButtonProps {
  shareData: ShareData;
}

const ShareButton: React.FC<ShareButtonProps> = ({ shareData, ...props }) => {
  const share = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content', error);
      }
    } else {
      alert('Web Share API not supported in this browser');
    }
  };

  return (
    <Button
      leftIcon={
        <Image
          src={ShareIcon}
          boxSize={props.size === 'sm' ? '20px' : undefined}
          mr={props.size === 'sm' ? 0 : 1}
          mt={props.size === 'sm' ? -1 : 0}
        />
      }
      onClick={share}
      {...props}
    >
      Share
    </Button>
  );
};

export default ShareButton;
