import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { EarlyAccessBadge } from '@components/ui/EarlyAccessBadge';
import { useSendRoomMessage } from 'src/hooks';
import McFlex from '../McFlex/McFlex';
import PlayerToken from '../PlayerToken/PlayerToken';
import InGameMenu from './components/InGameMenu';
import SystemJoinButton from './components/SystemJoinButton';
import { GameNames } from '@/games';
import {
  useCurrentGameName,
  useNumPlayersWithoutLeavers,
  useOpenDrawer,
  usePlayerId,
  usePlayerName,
} from '@/store/store';

const PlayerNameBanner: React.FC = () => {
  const playerName = usePlayerName();
  return (
    <Heading color="MagicWhite" size="sm" textTransform="uppercase">
      {playerName}
    </Heading>
  );
};

const MagicCircleWordMark: React.FC = () => {
  return (
    <McFlex gap="2">
      <Heading color="MagicWhite" size="lg">
        Magic Circle
      </Heading>
      <Box>
        <EarlyAccessBadge />
      </Box>
    </McFlex>
  );
};

const SystemHeader: React.FC = () => {
  const playerId = usePlayerId();
  const sendRoomMessage = useSendRoomMessage();
  const currentGameName = useCurrentGameName();
  const openDrawer = useOpenDrawer();
  const numPlayers = useNumPlayersWithoutLeavers();
  const [showEndGameModal, setShowEndGameModal] = useState(false);

  const onConfirmEndGame = () => {
    sendRoomMessage({ type: 'RequestGame', name: 'Lobby' });
    setShowEndGameModal(false);
  };

  const closeModal = () => {
    setShowEndGameModal(false);
  };

  const isInGame = currentGameName !== GameNames.Lobby;

  return (
    <McFlex id="SystemHeader" orient="space-between" px={4} bg="MagicBlack">
      <Modal isOpen={showEndGameModal} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent w="90%">
          <ModalHeader>
            <Heading size="lg">End Game for Everyone?</Heading>
          </ModalHeader>
          <ModalBody>
            <Text color="White">
              This will end the game for everyone. Are you sure?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="white"
              size="sm"
              fontWeight="bold"
              fontFamily="Greycliff CF"
              mr={4}
              onClick={closeModal}
            >
              KEEP PLAYING
            </Button>
            <Button
              variant="red"
              size="sm"
              fontWeight="bold"
              fontFamily="Greycliff CF"
              onClick={onConfirmEndGame}
            >
              END GAME
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        {isInGame ? (
          <InGameMenu />
        ) : (
          <McFlex>
            <SystemJoinButton />
          </McFlex>
        )}
      </Box>
      {isInGame && <PlayerNameBanner />}
      {!isInGame && numPlayers < 2 && <MagicCircleWordMark />}
      <PlayerToken playerId={playerId} onClick={() => openDrawer('profile')} />
    </McFlex>
  );
};

export default SystemHeader;
