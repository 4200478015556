import { defineStyleConfig } from '@chakra-ui/react';

const sizes = {
  xs: '36px',
  sm: '50px',
  md: '70px',
  lg: '120px',
};

const McCircleTheme = defineStyleConfig({
  baseStyle: {
    alignItems: 'center',
    borderColor: 'white', // change this for mc white
    borderRadius: '1000px',
    borderWidth: '3px',
    display: 'flex',
    fontFamily: 'Baloo', // change this too
    justifyContent: 'center',
  },
  sizes: {
    xs: {
      boxSize: sizes.xs,
      minH: sizes.xs,
      minW: sizes.xs,
      fontSize: '24px',
    },
    sm: {
      boxSize: sizes.sm,
      minH: sizes.sm,
      minW: sizes.sm,
      fontSize: '35px',
    },
    md: {
      boxSize: sizes.md,
      minH: sizes.md,
      minW: sizes.md,
      fontSize: '65px',
    },
    lg: {
      borderWidth: '4px',
      boxSize: sizes.lg,
      minH: sizes.lg,
      minW: sizes.lg,
      fontSize: '115px',
    },
  },
  defaultProps: {
    size: 'sm',
  },
});

export default McCircleTheme;
