import { IState } from './types/state';

export function findScopeInStateChain(
  state: IState<unknown> | null,
  scopes: string[]
): IState<unknown> | null {
  if (!state || scopes.length === 0) return null;

  // Check if the current state's scope matches the first scope in the scopes array
  if (state.scope === scopes[0]) {
    // If we only have one scope left in the scopes array, return the state
    if (scopes.length === 1) {
      return state;
    } else {
      // If not, proceed with the state's child and the rest of the scopes
      return findScopeInStateChain(state.child, scopes.slice(1));
    }
  }

  // If the scope does not match, return null
  return null;
}

export type Widen<T> = T extends number
  ? number
  : T extends string
  ? string
  : T extends boolean
  ? boolean
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<Widen<U>>
  : T extends object
  ? { [K in keyof T]: Widen<T[K]> }
  : never;

type ImmutablePrimitive =
  | undefined
  | null
  | boolean
  | string
  | number
  // eslint-disable-next-line @typescript-eslint/ban-types
  | Function;
type ImmutableArray<T> = ReadonlyArray<Immutable<T>>;
type ImmutableMap<K, V> = ReadonlyMap<Immutable<K>, Immutable<V>>;
type ImmutableSet<T> = ReadonlySet<Immutable<T>>;
type ImmutableObject<T> = { readonly [K in keyof T]: Immutable<T[K]> };

// https://github.com/microsoft/TypeScript/issues/13923#issuecomment-557509399
export type Immutable<T> = T extends ImmutablePrimitive
  ? T
  : T extends Array<infer U>
  ? ImmutableArray<U>
  : T extends Map<infer K, infer V>
  ? ImmutableMap<K, V>
  : T extends Set<infer M>
  ? ImmutableSet<M>
  : ImmutableObject<T>;
