import { useEffect, useRef } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import SystemDrawer from '@components/SystemDrawer/SystemDrawer';
import ViewRouter from '@components/ViewRouter';
import { getDefaultStore } from 'jotai';
import SystemHeader from './components/SystemHeader/SystemHeader';
import { ConnectionInterruptedDialog } from './connection/ConnectionInterruptedDialog';
import { PermanentlyDisconnectedDialog } from './connection/PermanentlyDisconnectedDialog';
import { useRoomConnection } from './connection/hooks';
import gameViews, { GameName } from './games';
import GameStartingModal from './games/Lobby/components/GameStartingModal';
import {
  playerNameAtom,
  useOpenDrawer,
  useSetGameDetails,
} from './store/store';
import { getQueryParams } from './utils';
import NoSleep from './utils/NoSleep';

// Prevent the page from going to sleep.
// Note: we can only do this after the user has interacted with the page.
document.addEventListener('touchstart', NoSleep.enable);

const App = () => {
  const openDrawer = useOpenDrawer();
  const inspectGame = useSetGameDetails();
  const hasJoined = useRef<boolean>(false);
  const roomConnection = useRoomConnection();

  useEffect(() => {
    // Protects against StrictMode double-render
    if (!hasJoined.current) {
      hasJoined.current = true;
      roomConnection.join();
    }
  }, [roomConnection]);

  useEffect(() => {
    const queryParams = getQueryParams();

    const inviterId = queryParams.get('inviterId');
    const selectedGame = queryParams.get('game');
    // don't usePlayer() because it will cause the ENTIRE APP to re-render
    // whenever the playe name or cosmetic changes
    const playerName = getDefaultStore().get(playerNameAtom);

    inviterId && !playerName && openDrawer('profile-afterAcceptingInvite');
    selectedGame && inspectGame(selectedGame as GameName);

    // Remove query params from URL if they exist
    if (queryParams.toString().length > 0) {
      const url =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname;
      history.replaceState(null, '', url);
    }
  }, [inspectGame, openDrawer]);

  return (
    <Grid id="App" h="100%" templateRows="var(--system-header-height) 1fr">
      <PermanentlyDisconnectedDialog />
      <ConnectionInterruptedDialog />
      <SystemHeader />
      <Box id="GameWindow" pos="relative" h="100%" overflow="auto">
        <SystemDrawer />
        <GameStartingModal />
        <ViewRouter views={gameViews} />
      </Box>
    </Grid>
  );
};

export default App;
